import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios'; 
import EntityInformation from './entityinformation'
import EmailVerification from './emailVerification'
import PhoneVerification from './phoneVerification'
import { useNavigate, Link, json } from 'react-router-dom';
import AccountInformation from './accountInformation'
import Dashboard from '../dashboard/dashboard';
import FadeLoader from "react-spinners/FadeLoader";
import { keys } from '@mui/material/styles';
const SignUP = (props) => {
    const navigate = useNavigate();
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [isEmailVerification, setIsEmailVerification] = useState(false);
    const [isPhoneVerification, setIsPhoneVerification] = useState(false);
    const [isAccountInfo, setAccountInfo] = useState(false);
    const [isopenDashboard, setDashboard] = useState(false);
    const [isEntityInformation, setEntityInformation] = useState(true)
    const [isentitypersoninfo, setentitypersoninfo] = useState(false)
    const [loginUserDetails, setloginUserDetails] = useState({})
    const [entityStep2, SetEntityStep2] = useState(false)
    const [isFlagSet, setIsFlagSet] = useState(false);
    const [phoneverificationrequired, setPhoneVerification] = useState(true);
    const [settingpage, setSettingpage] = useState(false);
    const otpSentRef = useRef(false);
    const emailSentRef = useRef(false);
    const [formData, setFormData] = useState({
        EntityName: "",
        EnityMailingAdderess: "",
        EnityMailingAdderess2: "",
        Country: "United States",
        CityId: "",
        Zipcode: "",
        StateId: "",
        FirstName: "",
        LastName: "",
        title: "",
        EmailId: "",
        MobileNumber: "",
        OfficeNumber: "",
    });
    const [loader, setLoader] = useState(false);
    const onsendingmail = (formData) => {
        setIsEmailVerification(true)
        setEntityInformation(false)
        setAccountInfo(false)
        setloginUserDetails(formData)
    }

    const onsendingSMS = (formData) => {
        setIsPhoneVerification(true)
        setAccountInfo(false)
        setEntityInformation(true)
        setloginUserDetails(formData)
    };

    const onemailVerificationSuccess = () => {
        setAccountInfo(false)
        setIsEmailVerification(false);
        setEntityInformation(false)
    }

    const onPhoneVerificationSuccess = () => {
        // setAccountInfo(true)
        setIsPhoneVerification(false)
        setEntityInformation(false)
    }

    const Accountinfo = () => {
        setDashboard(!isopenDashboard)
    }

    const onEnityInfo = () => {
        SetEntityStep2(!entityStep2)
        setentitypersoninfo(true)
    }
    const onEnityScreen = () => {
        setIsPhoneVerification(false)
        setIsEmailVerification(false)
        setEntityInformation(true)
        setentitypersoninfo(true)
    }
    const onAccountpage = () => {
        setAccountInfo(true)
        setIsEmailVerification(false)
        setIsPhoneVerification(false)
        setEntityInformation(false)
    }

    const handlebackAccountinfo = () => {
        SetEntityStep2(!entityStep2)
        setIsFlagSet(false);
        setEntityInformation(true)
        setentitypersoninfo(true)
    }

    const sendOTPByEmail = async (emailOTP) => {
        setLoader(true);
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "x-functions-key": hostHeader,
                },
                body: JSON.stringify(emailOTP),
            };
            const response = await axios.post(
                `${hostPath}/api/SendOtpToEmail`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );
            if (response?.data) {
                setLoader(false);
                onsendingmail(formData);
            }
        } catch (error) {
            console.error("Error sending email:", error);
        }
        finally {
            setLoader(false);
        }
    };
 const sendOTPByPhone = async ({ MobileNumber }) => {
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    "x-functions-key": hostHeader,
                },
                body: JSON.stringify({ CellNumber: MobileNumber }),
            };
            const response = await axios.post(
                `${hostPath}/api/SendOtpToSMS`,
                requestOptions.body,
                { headers: requestOptions.headers }
            );
            if (response.data) {
                onsendingSMS(formData);
            }
        } catch (error) {
            console.error("Error sending SMS:", error);
        }
    };
    
    const handleVerificationEmail = () => {
        const { verificationRequired, personaldata } = props;
    
        if (verificationRequired === 'email' && !otpSentRef.current) {
            setIsEmailVerification(true);
            setEntityInformation(false);
            setPhoneVerification(false);
            sendOTPByEmail?.({ EmailId: personaldata?.NewEmailId ?? '' });
            otpSentRef.current = true;
        } else if (verificationRequired === 'emailmobile' && !otpSentRef.current) {
            setPhoneVerification(true);
            setIsEmailVerification(true);
            sendOTPByEmail?.({ EmailId: personaldata?.NewEmailId ?? '' });
            otpSentRef.current = true;
        } else if (verificationRequired === 'mobile' && !otpSentRef.current) {
            setIsEmailVerification(false);
            setIsPhoneVerification(true);
            // setTimeout(() => {
                sendOTPByPhone?.({ MobileNumber: personaldata?.MobileNumber ?? '' });
                otpSentRef.current = true;
            // }, 1000);
        }
    };
    
    useEffect(() => {
        if (props.personaldata && props.entityData) {
            setSettingpage(true);
            setEntityInformation(false);
            if (props.radiobuttonval === 'email' && !emailSentRef.current) {
                setIsEmailVerification(true);
                setPhoneVerification(false);
                sendOTPByEmail?.({ EmailId: props?.personaldata?.EmailId });
                emailSentRef.current = true;
            }
            else if (props.radiobuttonval === 'phone' && !emailSentRef.current) {
                setIsPhoneVerification(true);
                sendOTPByPhone({ MobileNumber: props?.oldNumber });
                emailSentRef.current = true;
            }
            const mergedData = { ...props?.personaldata, ...props?.entityData };
            setFormData(prevFormData => ({
                ...prevFormData,
                ...mergedData
            }));
        }
    }, []);
    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            {
                isEmailVerification ?
                    <EmailVerification
                        sendOTPByEmail={sendOTPByEmail} sendOTPByPhone={sendOTPByPhone} emailVerificationSuccess={onemailVerificationSuccess}
                        // onAccountpage={onAccountpage}
                        onEnityInfo={onEnityScreen} formData={formData} setAccountInfo={setAccountInfo}
                        phoneverificationrequired={phoneverificationrequired} settingpage={settingpage} updateProfile={props.updateProfile}
                        profiledata={props.personaldata} setRadiobuttonval={props.setRadiobuttonval} radiobuttonval={props.radiobuttonval}
                        handleverificationemail={handleVerificationEmail} otpSentRef={otpSentRef.current} emailSentRef ={emailSentRef.current}
                        handleBack = {props.handleBack} verificationRequired ={props.verificationRequired}
                    /> : null
            }
            {
                isPhoneVerification ?
                    <PhoneVerification
                        sendOTPByPhone={sendOTPByPhone} phoneVerificationSuccess={onPhoneVerificationSuccess}  onAccountpage={onAccountpage}
                        onEnityInfo={onEnityScreen}  formData={formData} setAccountInfo={setAccountInfo} handleverificationemail={handleVerificationEmail}
                        settingpage={settingpage}  updateProfile={props.updateProfile} profiledata={props.personaldata} otpSentRef={otpSentRef.current}
                        oldNumber={props.oldNumber} emailSentRef ={emailSentRef.current} radiobuttonval={props.radiobuttonval} handleBack = {props.handleBack}
                        verificationRequired ={props.verificationRequired}
                    /> : null
            }
            {isEntityInformation ?
                <EntityInformation
                    onsendingmail={onsendingmail} onsendingSMS={onsendingSMS} handleButtonClick={isentitypersoninfo}
                    setIsFlagSet={setIsFlagSet} isFlagSet={isFlagSet} formData={formData}
                    setFormData={setFormData} sendOTPByEmail={sendOTPByEmail} sendOTPByPhone={sendOTPByPhone} settingpage={settingpage}
                /> : null
            }
            {
                isAccountInfo ? <AccountInformation
                    loginUserDetails={loginUserDetails} onAccountinfo={Accountinfo} onEnityInfo={handlebackAccountinfo}
                /> : null
            }
            {
                isopenDashboard ? <Dashboard
                /> : null
            }
        </div>
    );
};

export default SignUP;






