import React, { useState } from "react";
import RobotaxiLogin from '../../Images/LoginImage.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_URLS from '../apiCalls/apiUrls';
import ValidationMessages from '../utils/validationMessages';
import {fetchGetInternalEmployeeDetailsByEmailId} from '../../Services/employeeService'

const GeoParkingInternalEmployeeVerification = (props) => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [otp, setOtp] = useState(new Array(5).fill(""));
    const [validationMessage, setValidationMessage] = useState("");
    const[geoempEmail,setGeoempemail] = useState();
    const navigate = useNavigate();
    const EmailId = props?.EmailId;
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const goBack = () => {
        navigate('/');
    }
    const resendCode = (e) => {
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
       

        if (!otp || otp.join("") === "") {
            setValidationMessage(ValidationMessages.OTPRequired);
            return;
        }
        const requestOptions = { method: "POST", headers: { "x-functions-key": hostHeader, }, body: JSON.stringify({ EmailId: EmailId.toLowerCase(), OTP: otp.join(""), }), };
        try {
            const response = await axios.post(
                `${hostPath}${API_URLS.BASE_URL_VERIFY_OTP}`, requestOptions.body,
                { headers: requestOptions.headers }
            );

            if (response.data && response.data.status === true) {
                setGeoempemail(EmailId);
                const geoempdata = await fetchGetInternalEmployeeDetailsByEmailId(EmailId);
                if(geoempdata){
                    const firstInitial = geoempdata?.firstName?.charAt(0);
                    const lastInitial = geoempdata?.lastName?.charAt(0);
                    const initials = firstInitial && lastInitial ? (firstInitial + lastInitial).toUpperCase() : '';
                    localStorage.setItem('companyEmail', JSON.stringify(geoempdata?.companyEmail));
                    localStorage.setItem('initials', JSON.stringify(initials));
                    localStorage.setItem('empId', JSON.stringify(geoempdata?.empId));
                }
                navigate('/dashboard');
            } else {
            }
        } catch (error) {
        }
    };


    return (
        <div className="registration-container textleft">
            <div className="image-section">
                <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
            </div>
            <div className="form-section">
                <h1 className="geoparkingloginheader">Enter Your OTP</h1>
                <p className="otpDescription">We have sent a one time password OTP to your email, please enter it below</p>
                <form onSubmit={handleSubmit} className="otp-form">
                    <div className="otp-inpts">
                        {otp.map((data, index) => {
                            return (
                                <input
                                    className="otp-inputs"
                                    type="text"
                                    name="otp"
                                    maxLength="1"
                                    key={index}
                                    value={data}
                                    onChange={(e) => handleChange(e.target, index)}
                                    onFocus={(e) => e.target.select()}
                                />
                            );
                        })}
                    </div>
                    {validationMessage && (<div className="text-danger">{validationMessage}</div>)}
                    <div className="d-flex ustify-content-end">
                        <button type="button" className="goBackRegister register-button mt-3" onClick={goBack}>Go Back</button>
                        <button type="submit" className="otpbtn register-button mt-3" onSubmit={handleSubmit}> Verify OTP</button>
                    </div>
                    <p className="otp-resend "> Didn't receive the OTP? <a className="login-link" href onClick={resendCode}>Resend Code</a> </p>
                    <p className="otpDescription"> If you haven't received the email, please check your spam/junk folder. </p>
                </form>
            </div>
        </div>
    );
};

export default GeoParkingInternalEmployeeVerification;
