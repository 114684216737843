import axios from 'axios';
const hostPath = process.env.REACT_APP_API_BASE_URL;
const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
export const fetchUserDetails = async (EntityRegistrationID) => {


    try {
        const response = await fetch(`${hostPath}/api/GetUserDetailsbyid`, {
            method: 'POST',
            headers: { "x-functions-key": `${hostHeader}` },
            body: JSON.stringify({ EntityRegistrationID }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data[0];  // Return the fetched user data
    } catch (error) {
        console.error('Failed to fetch user details:', error);
        throw error;  // Re-throw the error to handle it in the component
    }
};
export const checkEmailExistence = async (email) => {
    const emailid = { EmailId: email };

    try {
        const response = await axios.post(
            `${hostPath}/api/CheckEmailIdExist`,
            emailid,
            { headers: { "x-functions-key": `${hostHeader}` } }
        );

        if (response.data === true) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error:', error);
        return false;  // Optionally return false or handle the error as needed
    }
};
export const fetchUserDetailsByEmpId = async (EmpId) => {
    try {
        const response = await fetch(`${hostPath}/api/GetUserDetailsbyEmpId`, {
            method: 'POST',
            headers: { "x-functions-key": `${hostHeader}` },
            body: JSON.stringify({ EmpId }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;  // Return the fetched user data
    } catch (error) {
        console.error('Failed to fetch user details:', error);
        throw error;  // Re-throw the error to handle it in the component
    }
};
export const deleteUserByEmail = async (email) => {
    const deleteEmail = { EmailId: email.toLowerCase() };  // Ensure email is lowercase

    try {
        const response = await axios.post(
            `${hostPath}/api/DeleteUserBasedOnEmailId`, 
            deleteEmail,
            {
                headers: { "x-functions-key": `${hostHeader}` },
            }
        );

        if (response.data) {
            // Handle the status of the response
            if (response.data.status) {
                return { success: true, message: 'User has been deleted successfully!' };
            } else {
                return { success: false, message: response.data.responseMessage };
            }
        } else {
            return { success: false, message: 'No response from the server. Please try again.' };
        }
    } catch (error) {
        console.error('Error during user deletion:', error);
        throw new Error('Failed to delete user. Please try again later.');
    }
};
export const sendTemppassword = async (reqbody) => {
    try {
        const response = await axios.post(
            `${hostPath}/api/SendTemporaryPasswordEmail`,
            reqbody,
            { headers: { "x-functions-key": `${hostHeader}` } }
        );
        console.log("response",response);
        if (response.data.status === "success") {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error:', error);
        return false; 
    }
};
export const entityRegistration = async (reqbody) => {
    try {
        const response = await axios.post(
            `${hostPath}/api/entityregistration`,
            reqbody,
            { headers: { "x-functions-key": `${hostHeader}` } }
        );
        if (response.data.status) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error:', error);
        return false; 
    }
};


