import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import atlas from 'azure-maps-control';
import axios from 'axios';
import { MdArrowBackIosNew } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";
import VerifyAlert from '../MyWorklist/VerifyAlert';
const ReviewAndConfirm = (props) => {
    const { overrides, handleReview, areasMarked, addressvalue, accountinfo, EmailId, latitude, longitude, toggleCondition, handleviewparking, defaultaddress, timeValues, selectedValue,streetName,isSalespersonloggedin, myworklistredirect,isautoSave,parkinglotId,events,...rest } = props;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const [searchedLocation, setsearchedLocation] = useState('')
    const [BankDetails, setBankDetails] = useState({})
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [shouldApprove,setShouldApprove] = useState(false);
    const[isPopupVisible,setPopupVisible] = useState(false);
    const navigate = useNavigate();
    var map;
    useEffect(() => {
        (addressvalue.length !== 0) ? setsearchedLocation(addressvalue) : setsearchedLocation(defaultaddress.trim());
        setBankDetails(accountinfo);
    }, [accountinfo, defaultaddress]);
    useEffect(() => {
        MapDetails();
    }, []);
    function MapDetails() {
        map = new atlas.Map('myMap', {
            center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
            zoom: 18,
            language: 'en-US',
            style: 'satellite',
            view: 'Auto',
            // Add authentication details for connecting to Azure Maps.
            authOptions: {
                authType: 'subscriptionKey',
                subscriptionKey: 'iKSTDEFy2eA9QfhSETwDwwnQoR_36iCV5wv18ubSOBw'
            }
        });
        const zoomControl = new atlas.control.ZoomControl();
        map.controls.add(zoomControl, {
            position: 'bottom-right'
        });

        var myCustomJson = areasMarked;
        map.events.add('ready', function () {
            var points = [];

            if (myCustomJson.length > 0) {
                for (var i = 0; i < myCustomJson.length; i++) {
                    // Create a position object from the lon and lat values.
                    var position = new atlas.data.Position(myCustomJson[i]?.Coordinates?.Longitude, myCustomJson[i]?.Coordinates?.Latitude);
                    // Create a new property for the substring of Name.
                    var nameSubstring = myCustomJson[i]?.Name?.substring(0, 3);

                    // Create a Point feature and pass in the result object as the properties so that we can access them later if needed.
                    var point = new atlas.data.Feature(
                        new atlas.data.Point(position),
                        {
                            Title: myCustomJson[i]?.Name?.substring(0, 3),
                            NameSubstring: nameSubstring
                        }
                    );

                    points.push(point);
                }
            }
            var datasource = new atlas.source.DataSource();
            map.sources.add(datasource);

            // Add point data to the data source.
            datasource.add(points);

            // Create a layer that defines how to render the points on the map.
            var symbolLayer = new atlas.layer.SymbolLayer(datasource, null, {
                textOptions: {
                    textField: ['get', 'NameSubstring'],
                    offset: [0, 1.2],
                    color: 'white'
                }
            });

            map.layers.add(symbolLayer);
        });
    }

    const backButtonClick = () => {
        handleReview(false);
        toggleCondition('4 of 5 - Enter Bank Details')
        toggleCondition('5 of 5 - Review and Confirm')
    }
    const CoardinateDetails = areasMarked.map(item => ({
        Latitude: item.Coordinates.Latitude.toString(),
        Longitude: item.Coordinates.Longitude.toString(),
        Title: item.Coordinates.Title,
        ID: item.id.toString()
    }));
    const Scheduleavailability =  events ;
      
    const handleButtonClick = (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);
        const email1 = {
            EmailId,
            Status:props.empId ? "Pending": "Submitted",
            StreetName: streetName,
            EntityRegistrationID: props.entityId,
            ParkingLotAddress: searchedLocation,
            EmpId:props.empId,
            BankDetails,
            CoardinateDetails,
            Scheduleavailability
        };

        
    
        const updateBankDetails = () => {
            BankDetails.EntityRegistrationID = props.entityId;
            return axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
                headers: { "x-functions-key": hostHeader }
            });
        };
    
        const navigateAfterResponse = (response) => {
            if (response.data) {
                if (myworklistredirect) {
                   navigate('/myworklist');
                } else {
                     if(props.empId)  navigate('/OwnerParkinglotRegistration ');
                     else navigate('/viewparkinginfo');
                }
              window.location.reload();
            }
        };
    
        const handleError = (error) => {
            console.error('Error:', error);
        };
    
        if (props.parkinglotObj) {
            let status = ( props.parkinglotObj.status === 'Draft'||props.parkinglotObj.status ==='Pending') && (!props.empId) ? 'Submitted' : props.parkinglotObj.status;
            if(props.parkinglotObj.status ==='Draft' && props.empId ){
               status = 'Pending';
            }
            const parkinglot = {
                EmailId: props.parkinglotObj.emailId,
                EntityRegistrationID: props.parkinglotObj.entityRegistrationID,
                ParkingLotAddress: searchedLocation,
                Parking_Lot_ID: props.parkinglotObj.parking_Lot_ID,
                id: props.parkinglotObj.id,
                CreatedDate: props.parkinglotObj.createdDate,
                Status: status,
                Comment: props.parkinglotObj.comment,
                StreetName: streetName,
                EmpId: props.empId,
                BankDetails,
                CoardinateDetails,
                Scheduleavailability
            };
    
            axios.post(`${hostPath}/api/UpdateParkingLotDetails`, parkinglot, {
                headers: { "x-functions-key": hostHeader }
            })
            .then(updateBankDetails)
            .then(navigateAfterResponse)
            .catch(handleError);
    
        } else if (isautoSave && parkinglotId) {
            const reqbody = {
                ...email1,
                        Parking_Lot_ID: parkinglotId.parkingLotId,
						id: parkinglotId.id,
						CreatedDate:parkinglotId.createdDate
            };
    
            axios.post(`${hostPath}/api/UpdateParkingLotDetails`, reqbody, {
                headers: { "x-functions-key": hostHeader }
            })
            .then(updateBankDetails)
            .then(navigateAfterResponse)
            .catch(handleError);
    
        } else {
            axios.post(`${hostPath}/api/ParkingLotBooking`, email1, {
                headers: { "x-functions-key": hostHeader }
            })
            .then(updateBankDetails)
            .then(navigateAfterResponse)
            .catch(handleError);
        }
    };
    
    const handleShouldapprove = (val1,val2) => {
        setShouldApprove(val1); 
        setPopupVisible(val2);
    }
    return (
        <div className="col-md-12 ">
            <div class="row m-4">
                <div className="col-lg-12 col-md-12 col-sm-12" style={{ textAlign: "left" }}>
                    <div><b>Review Marked Areas</b></div>
                    <div>Take a close look at the areas you've marked on the map to ensure they accurately reflect your intended designations.</div>
                </div>
            </div>
            <div class="row m-4"  >

                <div id="reviewAndConfirm" style={{}}>
                    <div class="row" style={{ textAlign: "left" }}>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div> <b>Street Address : {searchedLocation}</b></div>
                        </div>
                    </div>
                    <div class="row "  >
                        <div class="col-lg-12 col-md-12 col-sm-12  m-3">
                            <div>
                                <div id="myMap" style={{ height: '750px', width: '100%', margin: '0% 0% 1% -1%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isSalespersonloggedin ? (
                <>
                 <div className=" mb-3" style={{ textAlign: "end", position: "relative" }}>
                   <button
                        type="button"
                        className='icon-btn'
                        onClick={backButtonClick}
                        style={{ marginRight: "10px", position: "relative", right: "65px", bottom: "217px"}}>
                    <MdArrowBackIosNew size={24} style={{ marginLeft: '-9px' }} title="Backward" />
                    </button>
                    <button
                        type="button"
                        className='icon-btn'
                        onClick={handleButtonClick}
                        style={{ marginRight: "10px", position: "relative", right: "70px", bottom: "217px",width:"80px",background: "#3f8ed7",
                        color:"white",fontWeight:"bold"
                        }}>
                     Save
                    </button>
                    <button
                        type="button"
                        className='icon-btn-finish'
                        onClick={() => handleShouldapprove(false,true)}
                        style={{ marginRight: "10px", position: "relative", right: "76px", bottom: "217px" ,backgroundColor:"white"}}
                    >
                        Reject
                    </button>
                    <button
                       type="button"
                        className='icon-btn-finish'
                        onClick={() => handleShouldapprove(true,true)}
                        style={{ marginRight: "10px", position: "relative", right: "80px", bottom: "217px" }}>
                       Verify
                    </button>
                    </div>
                </>
            ) : (
                <div className=" mb-3" style={{ textAlign: "end", position: "relative" }}>
                <button type="submit" className='finishbtn' onClick={backButtonClick} style={{ marginRight: "10px", position: "relative", right: "80px", bottom: "217px" }}>Go Back</button>
                <button type="submit" className='nextBtn' onClick={handleButtonClick} disabled={isButtonDisabled} style={{ marginRight: "10px", position: "relative", right: "76px", bottom: "217px" }}>Finish</button>
                </div>
            )}
             {isPopupVisible && (
                <VerifyAlert handleShouldapprove={handleShouldapprove} shouldApprove={shouldApprove} isPopupVisible={isPopupVisible} BankDetails={BankDetails} Scheduleavailability={Scheduleavailability} CoardinateDetails={CoardinateDetails}
                parkinglotObj={props.parkinglotObj} searchedLocation={searchedLocation} streetName={streetName}  entityId ={props.entityId}
                 />
            )}
        </div>
    )
}
export default ReviewAndConfirm;