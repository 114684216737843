import React, { useState, useEffect } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CreatePassword from '../Owner/CreatePasswordForOwner'
import ValidationMessages from '../utils/validationMessages';

const OwnerLogin = () => {
    const [createPassword, setCreatePassword] = useState(0);
    const [isFormDisabled, setIsFormDisabled] = useState(false);
    const [owner, setLoginUserData] = useState({
        EmailId: '',
        tempPassword: '',
    });
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const validateForm = () => {
        const errors = {};
        if (!owner.EmailId) {
            errors.EmailId = ValidationMessages.emailRequired;
        }
        if (!owner.tempPassword) {
            errors.Password = ValidationMessages.passwordRequired;
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const login = (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (owner.tempPassword === "Geoparking@123") {
                setCreatePassword(true)
            }
        }

    }

    const handleChange = (e) => {
        setLoginUserData({ ...owner, EmailId: e.target.value });
    }

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <>
            {
                createPassword ? <CreatePassword
                    ownerlogin={owner} /> : <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-md-7 p-0">
                            <img src={RobotaxiLogin} alt="Login" style={{ width: "100%", height: "100vh" }} />
                        </div>
                        <div className="col-md-5">
                            <form className="login-form loginfirst">
                                <div className="login">
                                    <h3 className='loginheader textleft'>Login</h3>
                                    <p className="mb-4 loginDescription textleft">Log in to your GeoParking Technologies Dashboard</p>
                                </div>
                                <div className="Username mb-4 textleft formbox">
                                    <label htmlFor="username" className='userName loginlabel'>Email</label><span className='mandatorymark loginlabel'>*</span>
                                    <input type="text"
                                        className={`form-control ${owner.EmailId ? 'input-active' : ''}`}
                                        id="username"
                                        placeholder='Enter Email'
                                        value={owner.EmailId}
                                        onChange={handleChange}
                                        disabled={isFormDisabled}
                                    />
                                    {errors.EmailId && <div className="error-message mandatorymark">{errors.EmailId}</div>}
                                </div>
                                <div className="Password mb-3 textleft">
                                    <style>
                                        {`
                                input::-ms-reveal {
                                display: none;
                                }
                                `}
                                    </style>
                                    <label htmlFor="password" className='userName loginlabel'>Password</label><span className='mandatorymark loginlabel'>*</span>
                                    <div className="password-container" style={{ position: 'relative' }}>
                                        <input type={passwordVisible ? "text" : "password"}
                                            className={`form-control ${owner.Password ? 'input-active' : ''}`}
                                            id="password"
                                            placeholder='Enter Password'
                                            value={owner.Password}
                                            onChange={(e) => setLoginUserData({ ...owner, tempPassword: e.target.value })}
                                            disabled={isFormDisabled} />
                                        <IconButton
                                            data-testid="toggle-password-visibility"
                                            onClick={togglePasswordVisibility}
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </div>
                                    {errors.Password && <div className="error-message mandatorymark">{errors.Password}</div>}
                                </div>
                                <button type="submit" className='btnn-primary mb-3 login-button' onClick={login} disabled={isFormDisabled}>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
            }


        </>
    );
};

export default OwnerLogin;
