import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from './components/login/Login.jsx';
import EntityInformation from './components/login/entityinformation.jsx';
import EmailVerification from './components/login/emailVerification.jsx'
import PhoneVerification from './components/login/phoneVerification.jsx';
import AccountInformation from './components/login/accountInformation.jsx'
import Dashboard from './components/dashboard/dashboard.jsx'
import MyPropertiesHome from './components/myproporties/myProperties_Home.jsx'
import LocateProperty from './components/myproporties/locatePropert.jsx' 
import BankDetailsMain from './components/bankDetails/bankDetailsMain.jsx'
import ReviewAndConfirm from './components/reviewConfirm/reviewandConfirm.jsx'
import ScheduleAvailability from './components/scheduleAvailability/scheduleAvailability.jsx'
import ViewPrakingLotInfo from './components/reviewConfirm/viewParkingLotInfo.jsx'
import SignUP from './components/login/signUP.jsx' 
import ResetPassword from './components/ResetPassword/ResetPassword.jsx'
import ForgetPassword from './components/ResetPassword/ForgetPassword.jsx'
import LocatePropertNewScreen from './components/myproporties/LocatePropertNewScreen.jsx'
import MapDashboard  from './components/myproporties/MapDashboard.jsx'
import HomePage from './components/landingPage/HomePage.jsx';
import UserDelete from './components/UserDelete.jsx'
import PrivacyPolicy from './components/landingPage/components/PrivacyPolicy.jsx';
import TermsOfUse from './components/landingPage/components/TermsOfUse.jsx';
import TermsOfService from './components/landingPage/components/TermsOfService.jsx';
import UserVerify from './components/UserVerify.jsx';
import SettingEntityInfo from './components/Settings/SettingEntityInfo.jsx'
import Earnings from './components/dashboard/earnings.jsx';
import UserList from './components/UserList.jsx';
import GeoParkingUserRegistration from './components/GeoParkingUserRegitrstion/GeoParkingUserRegistration.jsx'
import MyWorklist  from './components/MyWorklist/MyWorklist.jsx';
import GeoParkingInternalEmployeeVerification from './components/login/geoParkingInternalEmployeeEmailVerification.jsx'
import OwnerRegistration from './components/Owner/OwnerRegistration.jsx'
import OwnerLogin from './components/Owner/OwnerLogin.jsx'
import CreatePassword from './components/Owner/CreatePasswordForOwner.jsx'
import DemoUserLogin from './components/DemoUser/DemoUserLogin.jsx'
import OwnerRegistationDashboard from './components/Owner/OwnerRegistationDashboard.jsx'
import OwnerParkinglotRegistration from './components/Owner/OwnerParkinglotRegistration.jsx'
import ProtectedRoute from '../src/ProtectedRoute.jsx'; // Import ProtectedRoute
import React, { useEffect, useState } from 'react';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState();

    // Load auth state from localStorage when the component mounts
    useEffect(() => {
        const authState = localStorage.getItem('isauth') === 'true';  // Check if 'true'
        setIsAuthenticated(authState);  // Set the initial authentication state
    }, []);
    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    
                    <Route path="/GeoParkingUserRegistration" element={<GeoParkingUserRegistration />} />
                    <Route 
                        path="/Home" 
                        element={<ProtectedRoute 
                            isAllowed={isAuthenticated}  // Only show login if not authenticated
                            element={<HomePage />} 
                            redirectPath="/"  // Redirect if already authenticated
                        />}
                    />
                     <Route 
                        path="/login" 
                        element={<ProtectedRoute 
                            isAllowed={isAuthenticated}  // Only show login if not authenticated
                            element={<Login />} 
                            redirectPath="/"  // Redirect if already authenticated
                        />}
                    />
                    <Route path="/signup" element={<SignUP />} />
                    <Route path="/entityinformation" element={<EntityInformation />} />
                    <Route path="/emailVerification" element={<EmailVerification />} />
                    <Route path="/phoneVerification" element={<PhoneVerification />} />
                    <Route path="/accountInformation" element={<AccountInformation />} />
                    {/* <Route path="/MarkArea" element={<MarkArea />} /> */}
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/myProperties_Home" element={<MyPropertiesHome />} />
                    <Route path="/locateproperty" element={<MapDashboard />} />
                    {/* <Route path="/bankDetailsMain" element={<BankDetailsMain />} />
                    <Route path="/scheduleAvailability" element={<ScheduleAvailability />} /> */}
                    <Route path="/reviewandConfirm" element={<ReviewAndConfirm />} />
                    <Route path="/viewparkinginfo" element={<ViewPrakingLotInfo />} />
                    <Route path="/ResetPassword" element={<ResetPassword />} />
                    <Route path="/ForgetPassword" element={<ForgetPassword />} />
                    <Route path="/LocatePropertNewScreen" element={<LocatePropertNewScreen />} />
                    <Route path='/UserDelete' element={<UserDelete/> }/>
                    <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                    <Route path='/terms-of-use' element={<TermsOfUse/>} />
                    <Route path='/terms-of-service' element={<TermsOfService/>} />
                    <Route path='/UserVerify' element={<UserVerify/> }/>               
                    <Route path='/earnings' element={<Earnings/> }/>
                    <Route path='/SettingEntityInfo' element={<SettingEntityInfo/> }/>
                    <Route path="/settings/:section" element={<SettingEntityInfo />} />
                    <Route path='/UserList' element={<UserList/> }/>  
                    <Route path="/myworklist" element={<MyWorklist />} />
                    <Route path="/GeoParkingInternalEmployeeVerification" element={<GeoParkingInternalEmployeeVerification />} />
                    <Route path='/OwnerRegistration' element={<OwnerRegistration/>} />
                    <Route path='/OwnerLogin' element={<OwnerLogin/>} />
                    <Route path='/CreatePassword' element={<CreatePassword/>} />
                    <Route path="/" element={<DemoUserLogin/>} />
                    <Route path='/OwnerRegistationDashboard' element={<OwnerRegistationDashboard/>} />
                    <Route path='/OwnerParkinglotRegistration' element={<OwnerParkinglotRegistration/>} />                 
             </Routes>
            </div>
        </BrowserRouter>
    );
};

export default App;
