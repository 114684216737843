import React, { useState } from "react";
import RobotaxiLogin from '../../Images/LoginImage.png';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import API_URLS from '../apiCalls/apiUrls';
import Departments from '../utils/dynamicDropDown';
import ValidationMessages from '../utils/validationMessages';
import { geoparkingEmailPattern } from '../utils/regexPatterns';
import { extendedAlphaNumericRegex } from '../utils/regexPatterns';
import EmailVerification from '../login/emailVerification';
import FadeLoader from "react-spinners/FadeLoader";


const GeoParkingUserRegistrationForm = () => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const navigate = useNavigate();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState(""); 
  const [Department, setDepartment] = useState("");
  const [TermsCondition, setTermsAndCondition] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEmailverification, setEmailVerification] = useState(false)
  const [loader, setLoader] = useState(false);
  

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    switch (name) {
      case "FirstName":
        setFirstName(value);
        if (!extendedAlphaNumericRegex.test(value) && value !== "") {
          setErrors({ ...errors, firstName: ValidationMessages.firstNameAlphaNumeric });
        } else if (value.trim() === "") {
          setErrors({ ...errors, firstName: ValidationMessages.firstNameRequired });
        } else {
          setErrors({ ...errors, firstName: "" });
        }
        break;

      case "LastName":
        setLastName(value);
        if (!extendedAlphaNumericRegex.test(value) && value !== "") {
          setErrors({ ...errors, lastName: ValidationMessages.lastNameAlphaNumeric });
        } else if (value.trim() === "") {
          setErrors({ ...errors, lastName: ValidationMessages.lastNameRequired });
        } else {
          setErrors({ ...errors, lastName: "" });
        }
        break;

      case "Email":
        setEmail(value);
        if (!geoparkingEmailPattern.test(value)) {
          setErrors({ ...errors, email: ValidationMessages.emailRequired });
        } else {
          setErrors({ ...errors, email: "" });
        }
        break;

      case "Phone":
        setPhone(value);
        const phoneNumber = value?.replace(/\D/g, "");
        if (phoneNumber?.length !== 11 || !phoneNumber?.startsWith("1")) {
          setErrors({ ...errors, phone: ValidationMessages.phoneRequired });
        } else {
          setErrors({ ...errors, phone: "" });
        }
        break;

      case "department":
        setDepartment(value);
        if (value.trim() === "") setErrors({ ...errors, department: ValidationMessages.departmentRequired });
        else setErrors({ ...errors, department: "" });
        break;

      case "T&C":
        setTermsAndCondition(checked);
        if (checked) {
          setErrors({ ...errors, TermsCondition: "" });
        } else {
          setErrors({ ...errors, TermsCondition: ValidationMessages.termsAndConditons });
        }
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    if (!FirstName.trim()) validationErrors.firstName = ValidationMessages.firstNameRequired;
    if (!LastName.trim()) validationErrors.lastName = ValidationMessages.lastNameRequired;
    if (!Email.trim()) validationErrors.email = ValidationMessages.emailRequired;
    else if (!geoparkingEmailPattern.test(Email)) validationErrors.email = ValidationMessages.emailFormatRequired;
    if (!Phone.trim()) validationErrors.phone = ValidationMessages.phoneRequired;
    else if (Phone) {
      const phoneNumber = Phone?.replace(/\D/g, "");
      if (phoneNumber.length !== 11 || !phoneNumber.startsWith("1")) {
        validationErrors.phone = ValidationMessages.inValidPhone;
      }
    }
    if (!Department.trim()) validationErrors.department = ValidationMessages.departmentRequired;
    if (TermsCondition === false) validationErrors.TermsCondition = ValidationMessages.termsAndConditons;

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    }
    return true;
  };

  const userRegistration = () => {
    const registerUserData = {
      "FirstName": FirstName,
      "LastName": LastName,
      "CompanyEmail": Email,
      "ContactNumber": Phone,
      "Department": Department,
    };
    const requestOptions = {
      method: 'POST',
      headers: { "x-functions-key": `${hostHeader}` },
      body: JSON.stringify(registerUserData)
    };
    axios.post(`${hostPath}${API_URLS.BASE_URL_RegisterInternalEmployee}`, requestOptions.body, { headers: requestOptions.headers })
      .then(response => {
        if (response.data.status === true) {
          const firstInitial = response.data.userData?.firstName ? response.data.userData?.firstName?.charAt(0) : '';
          const lastInitial = response.data.userData?.lastName ? response.data.userData?.lastName?.charAt(0) : '';
          const companyEmail = response.data.userData?.companyEmail ? response.data.userData?.companyEmail : '';
          const initials = firstInitial && lastInitial ? (firstInitial + lastInitial).toUpperCase() : '';
          const empId = response?.data?.userData?.empId
          localStorage.setItem('companyEmail', JSON.stringify(companyEmail));
          localStorage.setItem('initials', JSON.stringify(initials));
          localStorage.setItem('empId', JSON.stringify(empId));
          navigate('/dashboard');
        }
      })
      .catch(error => {
        setErrors({ error });
      });
  }

  const sendOTPByEmail = async (emailOTP) => {
    setLoader(true);
    const EmailId = {
      EmailId: emailOTP
    };
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-functions-key": hostHeader,
        },
        body: JSON.stringify(EmailId),
      };
      const response = await axios.post(
        `${hostPath}/api/SendOtpToEmail`,
        requestOptions.body,
        { headers: requestOptions.headers }
      );
      if (response?.data.status === true) {
        setEmailVerification(true)
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
    finally {
      setLoader(false);
    }
  };




  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendOTPByEmail(Email)
    }
  };


  return (
    <>
    {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
      {isEmailverification ? <EmailVerification
        isEmailverification={isEmailverification}
        Email={Email}
        userRegistration={userRegistration} /> :
        <div className="registration-container textleft">
          <div className="image-section">
            <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
          </div>
          <div className="form-section">
            <h1 className="geoparkingloginheader">Join GeoParking Sales</h1>
            <p className="internalempdescription">Unlock Your Potential in Smart Parking Solutions</p>
            <form onSubmit={handleSubmit}>
              <div className="FirstName mb-4 textleft">
                <label htmlFor="FirstName" className='FirstName loginlabel'>First Name</label>
                <span className='mandatorymark loginlabel'>*</span>
                <input
                  type="text"
                  id="FirstName"
                  name="FirstName"
                  placeholder="Enter First Name"
                  value={FirstName}
                  onChange={handleChange}
                />
                <div className="validation-error">
                  {errors.firstName && <span className="error">{errors.firstName}</span>}
                </div>
              </div>
              <div className="LastName mb-4 textleft">
                <label htmlFor="LastName" className='LastName loginlabel'>Last Name</label>
                <span className='mandatorymark loginlabel'>*</span>
                <input
                  type="text"
                  name="LastName"
                  id="LastName"
                  placeholder="Enter Last Name"
                  value={LastName}
                  onChange={handleChange}
                />
                <div className="validation-error">
                  {errors.lastName && <span className="error">{errors.lastName}</span>}
                </div>
              </div>
              <div className="Email mb-4 textleft">
                <label htmlFor="Email" className='Email loginlabel'>Email Address</label>
                <span className='mandatorymark loginlabel'>*</span>
                <input
                  type="email"
                  name="Email"
                  id="Email"
                  placeholder="Enter Email Address"
                  value={Email}
                  onChange={handleChange}
                />
                <div className="validation-error">
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
              </div>
              <div className="Phone mb-4 textleft">
                <label htmlFor="Phone" className='Phone loginlabel'>Phone Number</label>
                <span className='mandatorymark loginlabel'>*</span>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  name="Phone"
                  maxLength={15}
                  value={Phone}
                  id="Phone"
                  placeholder="Enter Your Contact Number"
                  defaultCountry="US"
                  countries={["US"]}
                  onChange={(value) => handleChange({ target: { name: "Phone", value } })}
                  className="Phone-Internal-Input"
                />
                <div className="validation-error">
                  {errors.phone && <span className="error">{errors.phone}</span>}
                </div>
              </div>
              <div className="Department mb-4 textleft">
                <label htmlFor="Department" className="Department loginlabel">Select the Department</label>
                <span className="mandatorymark loginlabel">*</span>
                <select
                  name="department"
                  value={Department}
                  id="Department"
                  onChange={handleChange}
                >
                  <option value="">Select Department</option>
                  {Departments.map((dept) => (
                    <option key={dept.id} value={dept.name}>
                      {dept.name}
                    </option>
                  ))}
                </select>
                <div className="validation-error">
                  {errors.department && <span className="error">{errors.department}</span>}
                </div>
              </div>
              <div className="textleft d-flex mt-4 mb-4 checkboxform">
                <input
                  className="checkbox"
                  type="checkbox"
                  name="T&C"
                  checked={TermsCondition}
                  onChange={handleChange}
                />
                <span className="TermsCondition">I agree to the terms and conditions</span>
              </div>

              <div className="validation-error mb-3">
                {errors.TermsCondition && <span className="error">{errors.TermsCondition}</span>}
              </div>
              <button type="submit" className="register-button mt-3">Register</button>
              <p className="login-link"> Already a member? <a href='/Login'>Log in</a> here.</p>
            </form>
          </div>
        </div>
      }
    </>

  );
}

export default GeoParkingUserRegistrationForm;
