import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import { extendedAlphaNumericRegex, digitRegex, alphabetRegex } from '../utils/regexPatterns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './OwnerRegistration.css';
import { checkEmailExistence, sendTemppassword, entityRegistration } from '../../Services/userService.js'
import FadeLoader from "react-spinners/FadeLoader";
import termsOfServicesData from '../TermsOfServices/TermsOfServices.json';
import termsOfUsesData from '../TermsOfServices/TermsOfUses.json'
import BootstrapDialog from '../BootstrapDialog/BootstrapDialog.jsx';

const OwnerRegistration = (props) => {
    const navigate = useNavigate();
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const loginURL = `${window.location.origin}/OwnerLogin`; // Dynamically build the login URL based on the environment
    const [loader, setLoader] = useState(false);
    const [dialogData, setDialogData] = useState({ title: '', sections: [] });
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        EntityName: '',
        EnityMailingAdderess: '',
        EnityMailingAdderess2: '',
        Country: 'United States',
        CityId: '',
        Zipcode: '',
        StateId: '',
        FirstName: '',
        LastName: '',
        Title: '',
        EmailId: '',
        MobileNumber: '',
        OfficeNumber: '',
        Password: 'Geoparking@123',
        TandC: false,
        ConfirmPassword: 'Geoparking@123',
    });

    const [errors, setErrors] = useState({});
    const [suggestions, setSuggestions] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    // Fetch states on component mount
    useEffect(() => {
        const fetchStates = async () => {
            try {
                const response = await axios.get('https://api.countrystatecity.in/v1/countries/US/states', {
                    headers: {
                        "X-CSCAPI-KEY": "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                    },
                });
                const sortedStates = response.data
                    .filter(state => extendedAlphaNumericRegex.test(state.iso2))
                    .sort((a, b) => a.name.localeCompare(b.name));
                setStates(sortedStates);
            } catch (error) {
                console.error("Error fetching states:", error);
            }
        };
        fetchStates();
    }, []);

    // Fetch cities based on state selection
    const fetchCities = async (stateId) => {
        try {
            const response = await axios.get(`https://api.countrystatecity.in/v1/countries/US/states/${stateId}/cities`, {
                headers: {
                    "X-CSCAPI-KEY": "MVFIREJGVWNpT0cwTWdtTTQ4eVhkQlBRRXB3YTNMdHpzYjE4aVF0cQ==",
                },
            });
            setCities(response.data);  // Update cities
            return response.data;
        } catch (error) {
            console.error("Error fetching cities:", error);
            return [];
        }
    };

    // Handle changes in input fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Handle search input changes and fetch address suggestions
    const handleSearchChange = async (e) => {
        const { value } = e.target;
        setFormData((prev) => ({ ...prev, EnityMailingAdderess: value }));

        if (value.trim().length > 2) {
            try {
                const response = await axios.get(
                    `https://atlas.microsoft.com/search/address/json?&subscription-key=EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG&api-version=1.0&language=en-US&query=${value}&countrySet=US`
                );
                const results = response.data.results || [];
                const addressSuggestions = results.map((result) => result.address);
                setSuggestions(addressSuggestions);
            } catch (error) {
                console.error("Error fetching address suggestions:", error);
            }
        } else {
            setSuggestions([]);
        }
    };

    // Handle address selection from suggestions
    const handleAddressSelect = async (address) => {
        try {
            // Find the selected state from the list of states
            const selectedState = states.find(
                (state) => state.name.toLowerCase() === address.countrySubdivisionName?.toLowerCase()
            );
            if (selectedState) {
                // Fetch cities for the selected state and then find the city
                const cityList = await fetchCities(selectedState.iso2);

                const selectedCity = cityList.find(
                    (city) => city.name.toLowerCase() === address.municipality?.toLowerCase()
                );

                // Update form data with selected city, state, and other address info
                setFormData((prevState) => ({
                    ...prevState,
                    CityId: selectedCity ? selectedCity.name : "", // Set city if found
                    StateId: selectedState?.name, // Set state
                    Zipcode: address.postalCode,
                    EnityMailingAdderess: address.freeformAddress?.split(',')[0] // First part of the address
                }));
            } else {
                console.warn("No matching state found for the address:", address.countrySubdivisionName);
            }
        } catch (error) {
            console.error("Error in handleAddressSelect:", error);
        } finally {
            setSuggestions([]);
        }
    };
    // Validate form inputs before submission
    const validateForm = async () => {
        const newErrors = {};
        const { EntityName, FirstName, LastName, Title, EmailId, Zipcode, CityId, StateId } = formData;

        if (!EntityName) newErrors.EntityName = "Entity Name is required";
        if (!FirstName || !alphabetRegex.test(FirstName)) newErrors.FirstName = "First Name is required and must contain only alphabets";
        if (!LastName || !alphabetRegex.test(LastName)) newErrors.LastName = "Last Name is required and must contain only alphabets";
        if (!Title || !alphabetRegex.test(Title)) newErrors.Title = "Title is required and must contain only alphabets";
        if (!Zipcode || !digitRegex.test(Zipcode) || Zipcode.length !== 5) newErrors.Zipcode = "Valid 5-digit ZIP code is required";
        if (!StateId) newErrors.StateId = "State is required";
        if (!CityId) newErrors.CityId = "City is required";
        const emailExists = await checkEmailExistence(formData.EmailId);
        if (!EmailId || !/\S+@\S+\.\S+/.test(EmailId)) {
            newErrors.EmailId = "Valid Email is required";
        }
        else if (emailExists) {
            newErrors.EmailId = "Email already exists";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true); // Start the loader
        try {
            const isValid = await validateForm();  // Wait for form validation to complete
            const updatedFormData = { ...formData, EmpId: props.empId };

            if (isValid) {
                const temppassword = {
                    name: formData.FirstName,
                    username: formData.EmailId,
                    loginLink: loginURL
                };
                // Send temporary password
                // const sendtemppassword = await sendTemppassword(temppassword);
                // if (sendtemppassword) {
                // Proceed with entity registration
                const entityRegistrationResponse = await entityRegistration(updatedFormData);
                if (entityRegistrationResponse) {
                    const entityId = entityRegistrationResponse.userData.entityRegistrationID;
                    props.handleRegsiterOwner(false);
                }
                // }
            }
        } catch (error) {
            console.error('Error during form submission:', error);
        } finally {
            setLoader(false); // Stop the loader once everything is done
        }
    };
    const handleCheckboxChange = () => {
        setFormData({
            ...formData,
            TandC: !formData.TandC,
        });
        errors.TandC = "";
    };
    const handleClickOpen = (type) => () => {
        let item;
        if (type === 'termsOfServices') {
            item = termsOfServicesData.termsOfServices[0];
        } else if (type === 'termsOfUses') {
            item = termsOfUsesData.termsOfUses[0];
        }
        console.log("item", item)
        if (item) {
            setDialogData(item);
            setOpen(true); // Open the dialog only when clicked
        } else {
            console.error("No data found.");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            {props.registerOwner && (
                <div className="OwnerRegistration-Container">
                    <h4>Owner Registration</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="section">
                            <h5>Personal Information</h5>
                            <div className="d-flex">
                                <InputField label="First Name" name="FirstName" value={formData.FirstName} onChange={handleInputChange} error={errors.FirstName} required />
                                <InputField label="Last Name" name="LastName" value={formData.LastName} onChange={handleInputChange} error={errors.LastName} required />
                                <InputField label="Title" name="Title" value={formData.Title} onChange={handleInputChange} error={errors.Title} required />
                                <InputField label="Email Address" name="EmailId" type="email" value={formData.EmailId} onChange={handleInputChange} error={errors.EmailId} required />
                            </div>
                            <div className="d-flex">
                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    name="MobileNumber"
                                    maxLength={15}
                                    value={formData.MobileNumber}
                                    id="MobileNumber"
                                    placeholder="Enter Your Contact Number"
                                    defaultCountry="US"
                                    onChange={(value) => handleInputChange({ target: { name: 'MobileNumber', value } })}
                                    className="Phone-Internal-Input"
                                />
                                <PhoneInput
                                    international
                                    countryCallingCodeEditable={false}
                                    name="OfficeNumber"
                                    maxLength={15}
                                    value={formData.OfficeNumber}
                                    id="OfficeNumber"
                                    placeholder="Enter Office Contact Number"
                                    defaultCountry="US"
                                    onChange={(value) => handleInputChange({ target: { name: 'OfficeNumber', value } })}
                                    className="Phone-Internal-Input"
                                />
                            </div>
                        </div>

                        <div className="section">
                            <h5>Entity Information</h5>
                            <div className="d-flex">
                                <InputField label="Entity Name" name="EntityName" value={formData.EntityName} onChange={handleInputChange} error={errors.EntityName} required />
                                <InputField
                                    label="EnityMailingAdderess"
                                    name="EnityMailingAdderess"
                                    value={formData.EnityMailingAdderess}
                                    onChange={handleSearchChange}
                                    error={errors.EnityMailingAdderess}
                                    required
                                />
                                {suggestions.length > 0 && (
                                    <ul className="search-results-owner">
                                        {suggestions.map((result, index) => (
                                            <li key={index} onClick={() => handleAddressSelect(result)}>
                                                {result.freeformAddress}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <InputField label="Mailing Address 2" name="EnityMailingAdderess2" value={formData.EnityMailingAdderess2} onChange={handleInputChange} />
                            </div>
                            <div className="d-flex">
                                <InputField label="ZIP Code" name="Zipcode" type="number" value={formData.Zipcode} onChange={handleInputChange} error={errors.Zipcode} required />
                                <DropdownField label="State" name="StateId" value={formData.StateId} options={states} onChange={handleInputChange} error={errors.StateId} required />
                                <DropdownField label="City" name="CityId" value={formData.CityId} options={cities} onChange={handleInputChange} error={errors.CityId} required />
                            </div>
                            <div className="d-flex">
                                <InputField label="Country" name="Country" type="text" value={formData.Country} readOnly required className="country" />

                                <div className="d-flex justify-content-between mb-2">
                                    <div className="remember">
                                        <label className="control control--checkbox mb-0">
                                            <input
                                                type="checkbox"
                                                checked={formData.TandC2}
                                            //  onChange={handleCheckboxChange2}
                                            />{" "}
                                            <span className="">
                                                I Agree to{" "}
                                                <a className="joingeo" onClick={handleClickOpen('termsOfUses')}>
                                                    {" "}
                                                    Terms of Use
                                                </a>{" "}
                                            </span>
                                            <div className="control__indicator"></div>
                                        </label>
                                        {errors.TandC2 && (
                                            <div className="error-message mandatorymark">
                                                {errors.TandC2}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-5">
                                    <div className="remember">
                                        <label className="control control--checkbox mb-0">
                                            <input
                                                type="checkbox"
                                                checked={formData.TandC}
                                            //   onChange={handleCheckboxChange}
                                            />{" "}
                                            <span className="">
                                                I Agree to{" "}
                                                <a className="joingeo" onClick={handleClickOpen('termsOfServices')}>
                                                    {" "}
                                                    Terms of Service
                                                </a>{" "}
                                            </span>
                                            <div className="control__indicator"></div>
                                        </label>
                                        {errors.TandC && (
                                            <div className="error-message mandatorymark">
                                                {errors.TandC}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BootstrapDialog
                            open={open}
                            onClose={handleClose}
                            title={`${dialogData.title}`}
                            content={dialogData.sections}
                            lastModified={`${dialogData.lastModified}`}
                            setOpen={setOpen}
                        />

                        <button type="submit" className="submit-button">Register</button>
                    </form>
                </div>
            )}
        </div>
    );
};

// Reusable Input Field Component
const InputField = ({ label, name, value, onChange, error, type = "text", required }) => (
    <div className={`form-field mb-4 ${name}`}>
        <label htmlFor={name} className="loginlabel">
            {label} {required && <span className="mandatorymark">*</span>}
        </label>
        <input type={type} name={name} id={name} value={value} onChange={onChange} placeholder={`Enter ${label}`} />
        {error && <div className="validation-error">{error}</div>}
    </div>
);

// Reusable Dropdown Field Component
const DropdownField = ({ label, name, value, options, onChange, error, required }) => (
    <div className={`form-field mb-4 ${name}`}>
        <label htmlFor={name} className="loginlabel">
            {label} {required && <span className="mandatorymark">*</span>}
        </label>
        <select name={name} id={name} value={value} onChange={onChange} className="form-control">
            <option value="">Select {label}</option>
            {options.map((option) => (
                <option key={option.iso2 || option.id} value={option.name}>
                    {option.name}
                </option>
            ))}
        </select>
        {error && <div className="validation-error">{error}</div>}
    </div>
);

export default OwnerRegistration;
