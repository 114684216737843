import axios from 'axios';
import {removeDoublequotesPattern} from '../components/utils/regexPatterns'
import API_URLS from '../components/apiCalls/apiUrls';
const hostPath = process.env.REACT_APP_API_BASE_URL;
const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;

export const fetchGetInternalEmployeeDetailsByEmailId = async (companyEmail) => {
    const internalEmployeeEmail = removeDoublequotesPattern(companyEmail);  // Assuming this function is already defined

    try {
        const response = await axios.post(`${hostPath}${API_URLS.BASE_URL_InternalEmployeeDetailsByEmailId}`, 
        {
            CompanyEmail: internalEmployeeEmail,
        }, 
        {
            headers: { "x-functions-key": hostHeader },
        });

        return response.data[0];  // Return the fetched employee data (assuming it's an array and we need the first item)
    } catch (error) {
        console.error('Failed to fetch internal employee details:', error);
        throw error;  // Re-throw the error to handle it in the component
    }
};
export const getLoggedInUser = () => {
    const loggedInUser = localStorage.getItem('empId');
    if (!loggedInUser) {
        console.error('No LoggedInUser found');
        return null;
    }
    try {
        const stateLoggedInUser = JSON.parse(loggedInUser);
        return stateLoggedInUser;
    } catch (error) {
        console.error('Error parsing LoggedInUser', error);
        return null;
    }
}