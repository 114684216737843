import { useState, useCallback, useEffect, useRef } from 'react';
import Frame1 from "./Frame1.jsx";
import PortalPopup from "./PortalPopup.jsx";
import atlas from 'azure-maps-control';
import Frame from "./Frame.jsx";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Scheduler from '../calendar/Scheduler.jsx';
import BankDetailsMain from '../bankDetails/bankDetailsMain.jsx';
import ReviewAndConfirm from '../reviewConfirm/reviewandConfirm.jsx';
import { GrFormSearch } from "react-icons/gr";
import MarkingAlert from '../NavBar/MarkingAlert.jsx';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { addDays, addWeeks, addMonths, format } from "date-fns";
import FadeLoader from "react-spinners/FadeLoader";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useNavigate } from "react-router-dom";
import ScheduleAvailability from '../scheduleAvailability/scheduleAvailability.jsx'

const MapDashboard = (props) => {
	const { overrides, ...rest } = props;
	const mapRef = useRef(null);
	const markerEnabled = useRef(false);
	let map;
	const [expanded, setExpanded] = useState(false);
	const hostPath = process.env.REACT_APP_API_BASE_URL;
	const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
	const [isFrameOpen, setFrameOpen] = useState(true);
	const [isFrame1Open, setFrame1Open] = useState(false);
	const [isAddressSelected, setConfirmAddress] = useState(false)
	const [isScheduleAvailabilityPopupConfirm, setConfirmforScheduleAvailability] = useState(true)
	const [searchValue, setSearchValue] = useState('');
	const [suggestions, setSuggestions] = useState([]);
	const [searchError, setSearchError] = useState(false);
	const [issearchdisabled, setSearchdisabled] = useState(false);
	const [addressvalue, setAddressvalue] = useState('');
	const [selectedOption, setSelectedOption] = useState([]);
	const [areasMarked, setareasmarked] = useState(props.areasMarked || []);
	const [scheduleavailability, setscheduleavailability] = useState(false);
	const [selectedValue, setSelectedValue] = useState(''); // Set default to Custom
	const [bankaccountdetails, setbankaccountdetails] = useState({});
	const [alertpopup, setAlertpopup] = useState(false);
	const [optionerror, setOptionerror] = useState(false);
	const [expandedMark, setExpandedMark] = useState(false)
	const [exitAlert, setExitAlert] = useState(false);
	const [weekoption, setWeekoption] = useState();
	const [streetname, setStreetname] = useState();
	const [currentProperty, setCurrentproperty] = useState();
	const [isDashboard, setDashboard] = useState(false)
	const [parkinglotId, setParkinglotId] = useState();
	const [parkinglotregistrationId, setParkinglotregistrationId] = useState();
	const [loader, setLoader] = useState(false);
	const [entity, setEntity] = useState(props.entityId || props.parkinglotObj?.entityRegistrationID)
	const [selectedDate, setSelectedDate] = useState(new Date());
	// MapDashboard.jsx
	const parseDate = (date) => {
		if (!date) return null;
		let parsedDate;
		if (typeof date === 'number') {
		  parsedDate = new Date(date);
		} else if (typeof date === 'string') {
		  if (/^\d+$/.test(date)) {
			// Numeric string, parse as integer
			parsedDate = new Date(parseInt(date, 10));
		  } else {
			// Try parsing as date string
			parsedDate = new Date(date);
		  }
		} else if (date instanceof Date) {
		  parsedDate = date;
		} else {
		  return null;
		}
		if (isNaN(parsedDate.getTime())) {
		  return null;
		}
		return parsedDate;
	  };
	  
	  const [events, setEvents] = useState(props?.parkinglotObj?.scheduleavailability || []);


	const logEvents = () => {
		console.log('Events in MapDashboard at the time of edit:', events);
	};



	let mapMarkers = useRef([]);
	let currentMarker = useRef();
	const [timeValues, setTimeValues] = useState(props?.parkinglotObj?.scheduleavailability?.timeSlot || {
		Monday: "",
		Tuesday: "",
		Wednesday: "",
		Thursday: "",
		Friday: "",
		Saturday: "",
		Sunday: "",
	});
	const steps = [
		"1 of 5 - Locate your Property",
		"2 of 5 - Mark Parking Spaces & Other Areas",
		"3 of 5 - Schedule Availability",
		"4 of 5 - Enter Bank Details",
		"5 of 5 - Review and Confirm"
	];
	const [areas, setAreas] = useState({
		"PSL - Parking Space Location": 0,
		"PKE - Parking Entrance": 0,
		"PKX - Parking Exit": 0,
		"PUE - Pick Up Entrance": 0,
		"DOE - Drop Off Entrance": 0,
		"PUL - Pick Up Location": 0,
		"DOL - Drop Off Location": 0,
		"PUX - Pick Up Exit": 0,
		"DOX - Drop Off Exit": 0
	});
	const [markingalert, setMarkingalert] = useState(false);
	const [conditions, setConditions] = useState({
		"1 of 5 - Locate your Property": true,
		"2 of 5 - Mark Parking Spaces & Other Areas": false,
		"3 of 5 - Schedule Availability": false,
		"4 of 5 - Enter Bank Details": false,
		"5 of 5 - Review and Confirm": false
	});
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [enableMarker, setEnablemarker] = useState(false);
	const [bankdetails, setBankdetails] = useState(false);
	const [reviewlocation, setReviewlocation] = useState(false);
	const [errors, setErrors] = useState({});
	const [errorsbank, setbankErrors] = useState({});
	const navigate = useNavigate();
	const [isautoSave, setAutosave] = useState(false);
	const [accountinfo, setAccountinfo] = useState({
		BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
		AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
		AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
		AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
		RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
		SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
	});
	const handleDatePickerChange = (date) => {
		setSelectedDate(date);
	};
	const lastSavedStateRef = useRef({
		addressvalue: '',
		areasMarked: props.areasMarked || [],
		timeValues: props?.parkinglotObj?.scheduleavailability?.timeSlot || timeValues,
		accountinfo: { ...accountinfo }
	});
	// Condition to disable the Save button if nothing has changed since the last save
	const isSaveDisabled =
		(addressvalue.length === 0 && areasMarked.length === 0) ||
		(addressvalue === lastSavedStateRef?.current?.addressvalue &&
			JSON.stringify(areasMarked) === JSON.stringify(lastSavedStateRef.current.areasMarked)
			&& JSON.stringify(accountinfo) === JSON.stringify(lastSavedStateRef.current.accountinfo)
			&& JSON.stringify(timeValues) === JSON.stringify(lastSavedStateRef.current.timeValues)
		);

	// Calculate if any value in areas is above 100
	const isAnyValueAbove100 = Object.values(areas).some(value => value >= 100);
	const minWidthStyle = isAnyValueAbove100 ? '30px' : '20px';

	function getLoggedInUser() {
		const loggedInUser = localStorage.getItem('LogedInUser');
		if (!loggedInUser) {
			console.error('No LoggedInUser found');
			return null;
		}
		try {
			const stateLoggedInUser = JSON.parse(loggedInUser);
			return stateLoggedInUser;
		} catch (error) {
			console.error('Error parsing LoggedInUser', error);
			return null;
		}
	}
	const stateLogedInUser = props?.userData?.emailId;
	const entityRegistrationId = props?.userData?.entityRegistrationID;
	const accordionStyles = {
		marginLeft: !scheduleavailability ? '0px' : '20px',
		flexGrow: !scheduleavailability ? 0 : 1
	};
	const mapStyles = {
		display: !scheduleavailability ? '' : 'flex'
	};
	const validateTimeValues = () => {
		const newErrors = {};
		switch (selectedValue) {
			case 'WeekDays':
				['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			case 'WeekEnd':
				['Saturday', 'Sunday'].forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			case 'Custom':
				if (!Object.values(timeValues).some(value => value)) {
					newErrors.general = "At least one day must have a value.";
				}
				break;
			case 'WholeWeek':
				Object.keys(timeValues).forEach(day => {
					if (!timeValues[day]) newErrors[day] = `${day} is required.`;
				});
				break;
			default:
				break;
		}
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};
	const handleInputChange = (account) => (e) => {
		const { name, value } = e.target;
		setAccountinfo({ ...accountinfo, [account]: value });
		const validateField = (name, value, regex, emptyMessage, invalidMessage) => {
			if (value.trim() === "") {
				setbankErrors(errors => ({ ...errors, [name]: emptyMessage }));
			} else if (regex && !regex.test(value)) {
				setbankErrors(errors => ({ ...errors, [name]: invalidMessage }));
			} else {
				setbankErrors(errors => ({ ...errors, [name]: "" }));
			}
		};
		switch (name) {
			case 'BankName':
				validateField(name, value, /^[a-zA-Z\s]*$/, '', 'Please enter alphabets only');
				break;
			case 'AccountHolderName':
				validateField(name, value, /^[a-zA-Z\s]*$/, '', 'Please enter alphabets only');
				break;
			case 'AccountType':
				validateField(name, value, /^[A-Za-z\s()]+$/, '', 'Please check the Account Type format');
				break;
			case 'AccountNumber':
				validateField(name, value, /^\d{8,12}$/, '', 'Account Number must be between 8 and 12 digits');
				break;
			case 'RoutingNumber':
				validateField(name, value, /^\d{9}$/, '', 'Routing Number must be exactly 9 digits');
				break;
			case 'SWIFT_BIC_Code':
				// Add validation if needed
				break;
			default:
				break;
		}
	};
	const handleCancelalert = (val) => {
		setMarkingalert(val);
	};
	const resetStates = () => {
		setExpanded(false);
		setFrame1Open(false);
		setConfirmAddress(false);
		setConfirmforScheduleAvailability(true);
		setSearchValue('');
		setSuggestions([]);
		setSearchError(false);
		setSearchdisabled(false);
		setAddressvalue('');
		setSelectedOption([]);
		setareasmarked([]);
		setscheduleavailability(false);
		setSelectedValue('');
		setAlertpopup(false);
		setOptionerror(false);
		setTimeValues({
			Monday: "", Tuesday: "", Wednesday: "",
			Thursday: "", Friday: "", Saturday: "", Sunday: "",
		});
		setConditions({
			"1 of 5 - Locate your Property": true,
			"2 of 5 - Mark Parking Spaces & Other Areas": false,
			"3 of 5 - Schedule Availability": false,
			"4 of 5 - Enter Bank Details": false,
			"5 of 5 - Review and Confirm": false
		});
		setEnablemarker(false);
		setBankdetails(false);
		setReviewlocation(false);
		setErrors({});
		setbankErrors({});
		setMarkingalert(false);
		searchLocation(props.defaultaddress);
		setAreas({
			"PSL - Parking Space Location": 0,
			"PKE - Parking Entrance": 0,
			"PKX - Parking Exit": 0,
			"PUE - Pick Up Entrance": 0,
			"DOE - Drop Off Entrance": 0,
			"PUL - Pick Up Location": 0,
			"DOL - Drop Off Location": 0,
			"PUX - Pick Up Exit": 0,
			"DOX - Drop Off Exit": 0
		});
		let mapElement = document.getElementById('myMap');
		if ((mapElement)) {
			map = new atlas.Map('myMap', {
				center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
				zoom: 18,
				language: 'en-US',
				style: 'satellite',
				authOptions: {
					authType: 'subscriptionKey',
					subscriptionKey: 'EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG'
				}
			});
			mapRef.current = map;
		}

	};
	const validateFields = () => {
		let tempErrors = {};
		let isValid = true;
		const validateField = (field, value, regex, emptyMessage, invalidMessage) => {
			if (value?.trim() === "" || value === null || value === undefined) {
				tempErrors[field] = emptyMessage;
				isValid = false;
			} else if (regex && !regex.test(value)) {
				tempErrors[field] = invalidMessage;
				isValid = false;
			}
		};
		validateField('BankName', accountinfo.BankName, /^[A-Za-z\s]+$/, 'Bank Name is required', 'Please enter alphabets only');
		validateField('AccountHolderName', accountinfo.AccountHolderName, /^[A-Za-z\s]+$/, 'Account Holder Name is required', 'Please enter alphabets only');
		validateField('AccountNumber', accountinfo.AccountNumber, /^\d{8,12}$/, 'Account Number is required', 'Account Number must be between 8 and 12 digits');
		validateField('RoutingNumber', accountinfo.RoutingNumber, /^\d{9}$/, 'Routing Number is required', 'Routing Number must be exactly 9 digits');
		validateField('AccountType', accountinfo.AccountType, /^[A-Za-z\s()]+$/, 'Account Type is required', 'Please Check the Account Type Format');
		setbankErrors(tempErrors);
		return isValid;
	};
	const handleReview = (val) => {
		setReviewlocation(val);
	}
	const timeSlotesAvailability = () => {
		if (validateTimeValues()) {
			setBankdetails(true);
			toggleCondition('4 of 5 - Enter Bank Details')
			toggleCondition('3 of 5 - Schedule Availability')
		}
	}
	useEffect(() => {
		if (weekoption === '') {
			setTimeValues({
				Monday: "",
				Tuesday: "",
				Wednesday: "",
				Thursday: "",
				Friday: "",
				Saturday: "",
				Sunday: "",
			});
		}
		setErrors({});
	}, [selectedValue]);

	const handlebankdetails = (val) => {
		setBankdetails(val);
	}
	const handlebankaccountdetails = (val) => {
		setbankaccountdetails(val);
	}

	const handleChange = (day) => (event) => {
		const { value } = event.target;
		setTimeValues({ ...timeValues, [day]: value });
		const newErrors = { ...errors };
		delete newErrors.general;
		delete newErrors[day];
		setErrors(newErrors);

	};

	const findKeyByValue = (obj, valueToFind) => {
		for (const [key, val] of Object.entries(obj)) {
			if (val === valueToFind) {
				return key;
			}
		}
		return null;
	};
	const handleselectedOption = (area) => {
		setEnablemarker(true);
		markerEnabled.current = true;
		setSelectedOption(area);
		setOptionerror(false);

	}
	const openFrame = useCallback(() => {
		setFrameOpen(true);
	}, []);

	const closeFrame = () => {
		setFrameOpen(false);
	};

	const openFrame1 = useCallback(() => {

		if ((addressvalue || props.defaultaddress) && !isAddressSelected) {
			setFrame1Open(true);
			setConfirmAddress(true)
		}
		if (isAddressSelected) {
			const hasPSLEntry = areasMarked.some(area => area.Name === 'PSL - Parking Space Location');
			if (selectedOption.length === 0 && issearchdisabled) {
				setOptionerror(true);
			} else if (selectedOption.length > 0 && (areasMarked?.length === 0 || !hasPSLEntry)) {
				handleCancelalert(true);

			}
			else {
				setFrame1Open(true);
			}
		}
		if (selectedOption.length > 0 && areasMarked.length > 0) {
			setConfirmforScheduleAvailability(true)
		}

	}, [addressvalue, isAddressSelected, selectedOption, issearchdisabled, areasMarked]);


	const closeFrame1 = useCallback(() => {
		setFrame1Open(false);
		setAlertpopup(false);
	}, []);

	const handleContinue = () => {
		if (selectedOption.length > 0) {
			setscheduleavailability(true)
			toggleCondition('2 of 5 - Mark Parking Spaces & Other Areas')
			toggleCondition('3 of 5 - Schedule Availability')
			setExpanded(true)
			closeFrame1();
		}
		else {
			setFrameOpen(true)
			setSearchdisabled(true);
			toggleCondition('1 of 5 - Locate your Property')
			toggleCondition('2 of 5 - Mark Parking Spaces & Other Areas')
			closeFrame1();
		}

	}
	const handleConfirm = (val) => {
		setscheduleavailability(val);
		setExpanded(false);
	}
	let markers = []; // Array to store all markers
	const selectedOptionRef = useRef(selectedOption);

	useEffect(() => {
		selectedOptionRef.current = selectedOption; // Update the ref value whenever selectedOption changes
	}, [selectedOption]);

	useEffect(() => {
		const initializeMap = () => {
			let mapElement = document.getElementById('myMap');
			if (mapElement) {
				map = new atlas.Map('myMap', {
					center: [longitude || -122.18402494821507, latitude || 47.61373420362662],
					zoom: 18,
					language: 'en-US',
					style: 'satellite',
					authOptions: {
						authType: 'subscriptionKey',
						subscriptionKey: 'EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG'
					}
				});
				mapRef.current = map;
				if (map && map.events && typeof map.events.add === 'function') {
					map.events.add('ready', function () {
						const zoomControl = new atlas.control.ZoomControl();
						if (map.controls && typeof map.controls.add === 'function') {
							map.controls.add(zoomControl, {
								position: 'bottom-right'
							});
						}
					});
				};
			}
		}
		if (latitude && longitude) {
			initializeMap();
			InitMap();

		}
	}, [latitude, longitude, scheduleavailability, props.areasMarked]);
	async function InitMap() {
		const map = mapRef.current;
		const createMarkerHTMLContent = (optionText, latitude, longitude) => {
			const text = typeof optionText === 'string' ? optionText : String(optionText);
			return `
				<div style="position: relative; width: 35px; height: 60px;" id="closeButton">
					<svg xmlns="http://www.w3.org/2000/svg" width="35" height="60" viewBox="0 0 24 36" fill="#6ACD54">
						<path d="M12 0C5.383 0 0 5.383 0 12c0 7.188 10.83 22.812 10.83 22.812C11.147 34.81 12 34.365 12 34.365S12.853 34.81 13.17 34.812C13.17 34.812 24 19.188 24 12C24 5.383 18.617 0 12 0z"/>
						<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black" font-size="8">${text?.substring(0, 3)}</text>
					</svg>
					<div class="marker-card" style="position: absolute; top: -65px; left: 26.5px; padding: 15px; background: white; border-radius: 10px; width: 230px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); display: none; z-index: 100;">
						<div id="closeButton" style="position: absolute; top: 5px; right: 5px; width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; color: black; cursor: pointer;">
							<img className="icround-gps-fixed-icon" alt="Delete" src="Delete.png" style="width: 100%; height: 100%;" />
						</div>					
						<div style="font-size: 12px; color: black; margin-bottom: 8px;">
						    ${text}<br />
							Drag to move, click to delete
						</div>
					</div>
				</div>`;
		};



		const addMarker = (latitude, longitude, optionText, id) => {
			const marker = new atlas.HtmlMarker({
				draggable: true,
				position: [longitude, latitude],
				htmlContent: createMarkerHTMLContent(optionText, latitude, longitude)
			});
			map?.markers?.add(marker);


			const markerElement = marker?.getElement();
			const closeButton = markerElement?.querySelector('#closeButton');

			if (closeButton) {
				markerElement?.addEventListener('mouseover', () => {
					const card = markerElement.querySelector('.marker-card');
					if (card) {
						card.style.display = 'flex';
					}
				});

				markerElement.addEventListener('mouseout', () => {
					const card = markerElement.querySelector('.marker-card');
					if (card) {
						card.style.display = 'none';
					}
				});

				closeButton.addEventListener('click', function (e) {
					map.markers.remove(marker);
					setAreas(prevAreas => ({
						...prevAreas,
						[optionText]: prevAreas[optionText] - 1
					}));
					setareasmarked(prev => prev.filter(m => m.id !== id));
					e.stopPropagation();
				});
			}

			map?.events?.add('dragend', marker, function () {
				const newPosition = marker.getOptions().position;
				const newLatitude = newPosition[1];
				const newLongitude = newPosition[0];
				setareasmarked(prev => {
					return prev.map(m => {
						if (m.id === id) {
							return {
								...m,
								latandlong: `Latitude: ${newLatitude} Longitude: ${newLongitude}`,
								Coordinates: {
									...m.Coordinates,
									Latitude: newLatitude,
									Longitude: newLongitude
								}
							};
						}
						return m;
					});
				});
			});

			const markdata = {
				id,
				"Name": optionText,
				"latandlong": `Latitude: ${latitude} Longitude: ${longitude}`,
				"Coordinates": {
					"Title": optionText,
					"Latitude": latitude,
					"Longitude": longitude
				}
			};

			const markerExists = areasMarked.some(area => (area.id === id || (area.Coordinates.Latitude === latitude && area.Coordinates.Longitude === longitude)));
			if (!markerExists) {
				setareasmarked(prev => [...prev, markdata]);
				setAreas(prevAreas => ({
					...prevAreas,
					[optionText]: prevAreas[optionText] + 1
				}));

			}

		};
		if (enableMarker) {
			if (map && map.events && map.events.add) {
				if (!map._listenersAdded) {
					map._listenersAdded = true;
					map.events.add('ready', function () {
						areasMarked.forEach(area => {
							const { Latitude, Longitude, Title, id } = area.Coordinates;
							addMarker(Latitude, Longitude, Title, area.id);
						});
					});

					map.events.add('click', function (e) {
						const clickedLatitude = e.position[1];
						const clickedLongitude = e.position[0];
						const newId = Date.now();
						addMarker(clickedLatitude, clickedLongitude, selectedOptionRef?.current, newId);
					});
				}
			}
		}
	}
	useEffect(() => {
		InitMap();
	}, [enableMarker, scheduleavailability]);

	useEffect(() => {
		if (props.areasMarked) {
			setTimeValues(props?.parkinglotObj?.scheduleavailability?.timeSlot);
			setWeekoption(props?.parkinglotObj?.scheduleavailability?.weekOption);
			setSelectedValue(props?.parkinglotObj?.scheduleavailability?.weekOption);
			setConditions({
				"1 of 5 - Locate your Property": false,
				"2 of 5 - Mark Parking Spaces & Other Areas": true,
				"3 of 5 - Schedule Availability": false,
				"4 of 5 - Enter Bank Details": false,
				"5 of 5 - Review and Confirm": false
			});
			const newCounts = { ...areas };

			areasMarked.forEach(marker => {
				const title = marker.Name;
				setSelectedOption(title);
				if (newCounts.hasOwnProperty(title)) {
					newCounts[title] = (newCounts[title] || 0) + 1;
				}
			});
			setAreas(newCounts);
			setSearchdisabled(true);
			setConfirmAddress(true);
			setOptionerror(false);
			setEnablemarker(true)
			setFrameOpen(false);
			setExpandedMark(true);
			const bankData = props?.parkinglotObj?.bankDetails;
			setbankaccountdetails(bankData);
			if (props.editOption === 'parkingschedule') {
				setscheduleavailability(true);
				setExpanded(true)
			}
			else if (props.editOption === 'bankdetails') {
				setscheduleavailability(true);
				setBankdetails(true);
				setExpanded(true)
			}
		}
	}, [props.areasMarked]);

	useEffect(() => {
		// Update all markers' text when selectedOption changes
		markers.forEach(marker => {
			if (marker._textElement) {
				marker._textElement.textContent = selectedOption?.substring(0, 3);
			}
		});
	}, [selectedOption]);
	const searchLocation = (locationName) => {
		var data = []
		data.location = locationName
		axios.get(`https://atlas.microsoft.com/search/address/json?&subscription-key=EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG&api-version=1.0&language=en-US&query=${locationName}`)
			.then((response) => {
				const results = response.data.results;
				if (results && results.length > 0) {
					const firstResult = results[0];
					const { position } = firstResult;
					setLatitude(position.lat);
					setLongitude(position.lon);
					const bounds = [];
					setCurrentproperty(firstResult?.address);
					setStreetname(firstResult?.address?.streetName);
					// InitMap();
				}
			})
			.catch((error) => {
				console.error('Error fetching location data:', error);
			});
	};

	useEffect(() => {
		if (props?.defaultaddress?.trim() !== '') {
			searchLocation(props.defaultaddress);
		}
	}, [props.defaultaddress]);
	const getDataFromAPI = (value) => {
		if (value) {
			const locationName = value.trim();
			if (locationName !== '') {
				setAddressvalue(locationName);
				setTimeout(() => {
					searchLocation(locationName, map);
				}, 1000);
			}

		}
		else {
			setAddressvalue('');
		}
	};
	useEffect(() => {
		if (searchValue?.trim() !== '') {
			// Fetch suggestions from API
			axios.get(`https://atlas.microsoft.com/search/fuzzy/json?&subscription-key=EN5vcaicwC78zKi3M3980VVB1m7DuuN0vKaDMtYdjBg7daKhx3OTJQQJ99AHAC8vTIngVrqvAAAgAZMPLtXG&api-version=1.0&language=en-US&query=${searchValue}&filter=countryCode=US`)
				.then(response => {
					const results = response.data.results;
					if (results && results.length > 0) {
						const suggestedLocations = results?.filter(result => result.address.countryCode === 'US').map(result => result.address.freeformAddress);
						setSuggestions(suggestedLocations);
					}
				})
				.catch(error => {
					console.error('Error fetching location suggestions:', error);
				});
		} else {
			setAddressvalue('');
			setSuggestions([]);

		}
	}, [searchValue]);

	const handleSearchChange = (value) => {
		const invalidCharacters = /[^a-zA-Z0-9\s,]/;
		if (invalidCharacters.test(value)) {
			setSearchError(true);
		} else {
			setSearchError(false);
		}
		setSearchValue(value);
	};

	const handleradiobutton = (event) => {
		const selectedValue = event.target.value;
		setSelectedValue(selectedValue);
		setWeekoption('');
	};
	useEffect(() => {
		setAccountinfo({
			BankName: bankaccountdetails ? bankaccountdetails.bankName : "",
			AccountHolderName: bankaccountdetails ? bankaccountdetails.accountHolderName : "",
			AccountType: bankaccountdetails ? bankaccountdetails.accountType : "",
			AccountNumber: bankaccountdetails ? bankaccountdetails.accountNumber : "",
			RoutingNumber: bankaccountdetails ? bankaccountdetails.routingNumber : "",
			SWIFT_BIC_Code: bankaccountdetails ? bankaccountdetails.swifT_BIC_Code : "",
			//Add_Ass_with_Account: bankaccountdetails ? bankaccountdetails.add_Ass_with_Account : "",
		});
	}, [bankaccountdetails]);

	useEffect(() => {
		if (entityRegistrationId) {
			const emailId = {
				"EmailId": stateLogedInUser
			}
			const requestOptions = {
				method: 'POST',
				headers: { "x-functions-key": `${hostHeader}` },
				body: emailId
			};
			axios.get(`${hostPath}/api/UpdateBankDetails/` + entityRegistrationId, { headers: requestOptions.headers })
				.then(response => {
					if (response.data) {
						const bankdetails = response.data;
						setbankaccountdetails(bankdetails);
					}
				})
				.catch(error => {
					console.error('Error:', error);
				});
		}
	}, [])
	const handleSave = async (e) => {
		e.preventDefault();

		if (isSaveDisabled) {
			return; // Prevent the save action if the conditions aren't met
		}

		const searchLocation = (addressvalue.length !== 0) ? addressvalue : props.defaultaddress.trim();

		const CoardinateDetails = areasMarked?.map(item => ({
			Latitude: item.Coordinates.Latitude.toString(),
			Longitude: item.Coordinates.Longitude.toString(),
			Title: item.Coordinates.Title,
			ID: item.id.toString()
		}));

		const Scheduleavailability = events.map((event) => ({
			...event,
			// No conversion of dates; keep them as strings
		  }));

		const BankDetails = accountinfo;
		BankDetails.EntityRegistrationID = entity;
		try {
			if (props.parkinglotObj) {
				setLoader(true);
				const parkinglot = {
					EmailId: props.parkinglotObj.emailId,
					EntityRegistrationID: props.parkinglotObj.entityRegistrationID,
					ParkingLotAddress: searchLocation,
					Parking_Lot_ID: props.parkinglotObj.parking_Lot_ID,
					id: props.parkinglotObj.id,
					CreatedDate: props.parkinglotObj.createdDate,
					Status: props.parkinglotObj.status,
					Comment: props.parkinglotObj.comment,
					StreetName: streetname || props.streetName,
					EmpId: props.parkinglotObj.empId?props.parkinglotObj.empId:0,
					BankDetails,
					CoardinateDetails,
					Scheduleavailability
				};

				await axios.post(`${hostPath}/api/UpdateParkingLotDetails`, parkinglot, {
					headers: {
						"x-functions-key": hostHeader
					}
				});

				await axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
					headers: {
						"x-functions-key": hostHeader
					}
				});
				lastSavedStateRef.current = {
					addressvalue,
					areasMarked: [...areasMarked],
					accountinfo: { ...accountinfo },
					events: { ...events }
				};
			} else if (addressvalue.length !== 0 || areasMarked.length > 0) {
				setLoader(true);
				const email1 = {
					EmailId: stateLogedInUser,
					Status: "Draft",
					StreetName: streetname,
					EntityRegistrationID: entity,
					ParkingLotAddress: searchLocation,
					EmpId:props.empId?props.empId:0,
					CoardinateDetails,
					Scheduleavailability
				};
				if (BankDetails.AccountNumber) {
					email1.BankDetails = BankDetails;
				}
				let reqbody = { ...email1 };

				if (parkinglotId) {
					reqbody = {
						...reqbody,
						Parking_Lot_ID: parkinglotId.parkingLotId,
						id: parkinglotId.id,
						CreatedDate: parkinglotId.createdDate
					};

					await axios.post(`${hostPath}/api/UpdateParkingLotDetails`, reqbody, {
						headers: {
							"x-functions-key": hostHeader
						}
					});
				} else {
					const parkinglotBookingResponse = await axios.post(`${hostPath}/api/ParkingLotBooking`, reqbody, {
						headers: {
							"x-functions-key": hostHeader
						}
					});

					if (parkinglotBookingResponse?.data) {
						setParkinglotId(parkinglotBookingResponse.data);
					}
				}
				if (BankDetails.AccountNumber) {
					await axios.post(`${hostPath}/api/UpdateBankDetails`, BankDetails, {
						headers: {
							"x-functions-key": hostHeader
						}
					});
				}
				lastSavedStateRef.current = {
					addressvalue,
					areasMarked: [...areasMarked],
					accountinfo: { ...accountinfo },
					events: { ...events }
				};
				setAutosave(true);

			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			setLoader(false); // Ensure loader is always turned off after API calls
		}
	};

	// MapDashboard.jsx

	





	const handleAccord = () => {
		setExpanded(!expanded);
	}
	const handleMarkerAccord = () => {
		setExpandedMark(!expandedMark);
	}
	const toggleCondition = (key) => {
		setConditions((prevConditions) => ({
			...prevConditions,
			[key]: !prevConditions[key],
		}));
	};
	const handleSearchclick = () => {
		if (!isAddressSelected) {
			setDashboard(true);
			setFrame1Open(true);
			setAlertpopup(false);
		} else {
			setAlertpopup(true);
			setFrame1Open(true);
		}
	}
	const navigateToDashboard = (isDashboard) => {
		navigate('/dashboard', { state: { isDashboard } });
	};
	const handleAddressreset = () => {
		resetStates();
	}

	const handleChangeAccordian = () => {
		setExpanded(!expanded);
	};
	const handleAccordMark = () => {
		setExpandedMark(!expandedMark);
	}
	const handleNavigate = (newDate) => {
		if (typeof newDate === 'string') {
			const currentDate = new Date(selectedDate);
			switch (newDate) {
				case 'PREV':
					currentDate.setMonth(currentDate.getMonth() - 1);
					setSelectedDate(new Date(currentDate));
					break;
				case 'NEXT':
					currentDate.setMonth(currentDate.getMonth() + 1);
					setSelectedDate(new Date(currentDate));
					break;
				case 'TODAY':
					setSelectedDate(new Date());
					break;
				default:
					break;
			}
		} else if (newDate instanceof Date) {
			setSelectedDate(newDate);
		}
	};
	return (
		<>
			{loader && (
				<div style={{
					position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000
				}}>
					<FadeLoader color="#36d7b7" loading={loader} />
				</div>
			)}



			<div className="map" style={{ width: '100%', height: '910px', overflow: 'hidden' }}>
				<div className="flex-container" style={mapStyles}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<AccordionSummary
							expanded={expanded}
							onChange={handleChangeAccordian}
							disableDivider
							sx={{ maxWidth: 400 }}
							style={{
								zIndex: 1000,
								position: 'relative',
								alignItems: 'center',
								padding: '50px',
								maxWidth: '350px',
							}}
							className="vector-parent"
						>
							<Accordion expanded={expanded} onChange={handleAccord}>
								<AccordionSummary
									expandIcon={
										expanded ? (
											<RemoveIcon data-testid="RemoveIcon" />
										) : (
											<AddIcon data-testid="AddIcon" />
										)
									}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									Property Registration Steps
								</AccordionSummary>
								{steps.map((step, index) => (
									<AccordionDetails
										key={step}
										className={`AccordName ${conditions[step] ? 'selected' : ''}`}
									>
										{step}
									</AccordionDetails>
								))}
							</Accordion>
						</AccordionSummary>

						{/* Date picker under AccordionSummary when scheduleavailability is true */}
						{scheduleavailability && !bankdetails && (
							<div style={{ marginBottom: '20px', marginTop: '40px', marginLeft: '40px' }}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<StaticDatePicker
										displayStaticWrapperAs="desktop"
										openTo="day"
										value={selectedDate}
										onChange={handleDatePickerChange}
										sx={{
											'& .MuiPickerStaticWrapper-root': {
												width: '100%',
											},
											'& .MuiPickersDay-root': {
												fontSize: '14px',
											},
											'& .MuiPickersDay-dayWithMargin': {
												margin: '1px',
											},
											'& .MuiCalendarPicker-root': {
												fontSize: '12px',
											},
											'& .MuiPickersCalendarHeader-label': {
												fontSize: '16px',
											},
											'& .MuiPickersArrowSwitcher-root button': {
												fontSize: '1.2rem',
											},
										}}
									/>
								</LocalizationProvider>
							</div>
						)}
					</div>
					<div style={accordionStyles}>
						{!scheduleavailability ? (
							<div>
								<div className="map-child" id="myMap" data-testid="myMap" style={{ width: '100%', height: "980px" }}></div>
								<div className="material-symbolsquestion-mark-wrapper" style={{ left: "400px" }} onClick={openFrame}>
									<img className="material-symbolsquestion-mark-icon" alt="" src="question-mark.png" />
								</div>
								<div className="next-wrapper" onClick={openFrame1}>
									<b className="next">Next</b>
								</div>
								<div className="back-wrapper" onClick={handleSearchclick}>
									<b className="next">Back</b>
								</div>
								<div
									className="autosave1-wrapper"
									onClick={(e) => handleSave(e)}
									style={{
										pointerEvents: isSaveDisabled ? 'none' : 'auto',
										opacity: isSaveDisabled ? 0.5 : 1,
									}}
								>
									<b className="next">Save</b>
								</div>
								<div className="component-1">
									<div style={{ flexShrink: "1", display: "flex" }}>
										{!issearchdisabled ? (
											<div className="enter-your-address">
												<Autocomplete
													style={{ width: 300, backgroundColor: "white", marginLeft: "-0%", borderRadius: "10px", flexShrink: "1" }}
													freeSolo
													autoComplete
													autoHighlight
													options={suggestions}
													getOptionLabel={(suggestions) => suggestions}
													filterOptions={(suggestions) => suggestions}
													onChange={(event, value) => getDataFromAPI(value)}
													onInputChange={(event, value) => setAddressvalue(value)}
													disabled={issearchdisabled}
													value={addressvalue}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={(e) => { handleSearchChange(e.target.value); }}
															variant="filled"
															label="Search Box"
															disabled={issearchdisabled}
															value={addressvalue}
														/>
													)}
												/>
												{searchError && (
													<div style={{ color: 'red', marginTop: '8px' }}>
														Enter alphabetic characters only
													</div>
												)}
											</div>) : <GrFormSearch
											data-testid="GrFormSearch"
											onClick={handleSearchclick} style={{ backgroundColor: 'white', height: '50px', width: '50px', borderRadius: "10px", marginTop: "13px", cursor: "pointer" }} />}
										{isAddressSelected && issearchdisabled ?
											<div className="enter-your-address">
												<AccordionSummary
													expanded={expandedMark} onChange={handleAccordMark}
													disableDivider sx={{ maxWidth: 500 }} style={{ zIndex: 1000, position: 'relative', alignItems: "center", Padding: "10px", }} className='vector-child '>
													<Accordion expanded={expandedMark} onChange={handleMarkerAccord}>
														<AccordionSummary
															expandIcon={expandedMark ? <RemoveIcon /> : <AddIcon />}
															aria-controls="panel1a-content"
															id="panel1a-header">Points to Mark on the Map
														</AccordionSummary>
														{Object.entries(areas).map(([key, value]) => (
															<AccordionDetails
																key={key}
																className={`AccordName1 ${selectedOption === key ? 'selected' : ''}`}
																onClick={() =>
																	handleselectedOption(key)}
															>
																<span style={{ display: 'inline-block', minWidth: minWidthStyle, textAlign: 'right' }}>
																	<strong>{value}</strong>
																</span>
																{"\u00A0\u00A0\u00A0\u00A0"}{key}
															</AccordionDetails>
														))}
													</Accordion>
												</AccordionSummary>
												{optionerror && (
													<div style={{ color: 'red', marginTop: '8px' }}>
														Select option to mark on the map
													</div>
												)}
											</div>
											: ""}
									</div>
								</div>
							</div>
						) : (
							!bankdetails ? (
								<Scheduler
									logEvents={logEvents}
									timeSlotesAvailability={timeSlotesAvailability}
									handleConfirm={handleConfirm}
									toggleCondition={toggleCondition}
									events={events}
									setEvents={setEvents}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									handleNavigate={handleNavigate}
									handleSave={handleSave}
									isSaveDisabled={isSaveDisabled}
								/>
							) : (
								!reviewlocation ? (
									<BankDetailsMain handlebankdetails={handlebankdetails} bankdetails={bankaccountdetails}
										handleReview={handleReview} validateFields={validateFields} handleInputChange={handleInputChange}
										errors={errorsbank} accountinfo={accountinfo} toggleCondition={toggleCondition} parkinglotObj={props.parkinglotObj}
										handleSave={handleSave} isSaveDisabled={isSaveDisabled}
									/>) : (
									<ReviewAndConfirm handleReview={handleReview} areasMarked={areasMarked} addressvalue={addressvalue}
										accountinfo={accountinfo} bankdetails={accountinfo} EmailId={stateLogedInUser} latitude={latitude} longitude={longitude} toggleCondition={toggleCondition}
										handleviewparking={props.handleviewparking} defaultaddress={props.defaultaddress} entityId={entity} parkinglotObj={props.parkinglotObj} events={events} selectedValue={selectedValue}
										streetName={streetname} isSalespersonloggedin={props.isSalespersonloggedin} myworklistredirect={props.myworklistredirect} isautoSave={isautoSave} parkinglotId={parkinglotId}
										empId={props.empId}
									/>
								)
							)
						)}
					</div>
				</div>
			</div>
			{isFrameOpen && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeFrame}
				>
					<Frame1 defaultaddress={props.defaultaddress} isFrameOpen={isFrameOpen} onClose={closeFrame} enableMarker={enableMarker} isScheduleAvailabilityPopupConfirm={isScheduleAvailabilityPopupConfirm} isAddressSelected={isAddressSelected} userData={props.userData} />
				</PortalPopup>
			)}
			{isFrame1Open && (
				<PortalPopup
					overlayColor="rgba(113, 113, 113, 0.3)"
					placement="Centered"
					onOutsideClick={closeFrame1}
				>
					<Frame isFrame1Open={isFrame1Open} onClose={closeFrame1} handleContinue={handleContinue} isScheduleAvailabilityPopupConfirm={isScheduleAvailabilityPopupConfirm} isAddressSelected={isAddressSelected} isDashboard={isDashboard}
						alertpopup={alertpopup} handleSearchclick={handleSearchclick} handleAddressreset={handleAddressreset} areasMarked={areasMarked} addressvalue={addressvalue} defaultaddress={props.userData} navigateToDashboard={e => navigateToDashboard(true)}
						currentProperty={currentProperty}
					/>
				</PortalPopup>
			)}
			{(markingalert || exitAlert) && (<MarkingAlert markingalert={markingalert} handleCancelalert={handleCancelalert} exitAlert={exitAlert}
				currentMarker={currentMarker.current} areasMarkedCount={areasMarked.length} hasPSLEntry={areasMarked.some(area => area.Name === 'PSL - Parking Space Location')}
			/>)}
		</>
	);
};

export default MapDashboard;
