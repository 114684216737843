import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import Navbar from '../NavBar/Navbar';
import { useNavigate } from 'react-router-dom';
import MapDashboard from '../myproporties/MapDashboard';
import FadeLoader from "react-spinners/FadeLoader";
import { IoEyeSharp } from "react-icons/io5";
import { BiSortAlt2 } from "react-icons/bi";
import StatusDropdown from '../MyWorklist/StatusDropdown'
import OwnerRegistration from './OwnerRegistration';
import { fetchUserDetailsByEmpId, deleteUserByEmail } from '../../Services/userService.js'
import { getLoggedInUser } from '../../Services/employeeService.jsx'

const OwnerRegistationDashboard = () => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const location = useLocation();
    const [userDetails, setUserdetails] = useState([]);
    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const actionRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const [userData, setUserdata] = useState();
    const [isEdit, setEdit] = useState(false);
    const [defaultaddress, setDefaultaddress] = useState();
    const [loader, setLoader] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(''); // This will store the selected status
    const [sortDirection, setSortDirection] = useState('asc'); // State for sorting direction
    const [isSalespersonloggedin, setSalespersonloggedin] = useState(true);
    const [registerOwner, setRegisterOwner] = useState(false);
    const [fetchUsers, setFetchusers] = useState(false);  // State to track if a user was deleted
     const [selectedRowEmailID,setSelectedRow] = useState();
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));
    // Function to handle status change from StatusDropdown
    const handleStatusChange = (selectedStatus) => {
        setSelectedStatus(selectedStatus);
    };
    const handleUserdetails = (val) => {
        setUserdetails(val);
    }
    const handleSort = () => {
        // Toggle the sorting direction
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        // Sort the data based on the 'createdDate' field
        const sortedData = [...filter].sort((a, b) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);

            if (newSortDirection === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        setFilter(sortedData);
    };
    const navigate = useNavigate();
    const handleRegsiterOwner = (val) => {
        setRegisterOwner(val);
    }
    const stateLogedInUser = getLoggedInUser();
    useEffect(() => {
        const fetchUserData = async () => {
            setLoader(true);  // Show loader before fetching data
            try {
                const userData = await fetchUserDetailsByEmpId(stateLogedInUser);
                setUserdetails(userData);  // Set fetched data
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                setLoader(false);  // Hide loader after data is fetched or error occurs
            }
        };

        fetchUserData();  // Call the async function
    }, [fetchUsers, registerOwner]);  // The empty array means this effect runs once after the component mounts.


    useEffect(() => {
        let result = userDetails;
        // Filter by search query
        if (search) {
            result = result.filter((item) => {
                return (item.parkingLotAddress || item.createdDate).toLowerCase().includes(search.toLowerCase());
            });
        }

        // Filter by selected status
        if (selectedStatus.length > 0) {
            result = result.filter((item) => selectedStatus.includes(item.status));
        }

        setFilter(result);
    }, [search, userDetails, selectedStatus]);
    const handleDelete = (val, typeOfButton) => {
        setSelectedRow(val.emailId)
        setOpen(true);
    };


    const handleEdit = (val, typeOfButton) => {
        setDefaultaddress(val?.enityMailingAdderess);
        setUserdata(val);
        setEdit(true);
    };

    const columns = [
        {
            name: "Nickname",
            selector: (row) => row.enityMailingAdderess,
            style: {
                minWidth: '200px', // Increase the minimum width of the column
            },
        },
        {
            name: "Street Address",
            selector: (row) => row.enityMailingAdderess,
            style: {
                minWidth: '200px', // Increase the minimum width of the column
            },
        },
        {
            name: "EmailID",
            selector: (row) => row.emailId,
            style: {
                minWidth: '120px', // Increase the minimum width of the column
            },
        },
        // {
        //     name: (
        //         <div style={{ display: 'flex', alignItems: 'start' }}>
        //             <StatusDropdown handleStatusChange={handleStatusChange} />
        //         </div>
        //     ),
        //     selector: (row) => row?.status,
        // },

        {
            name: (
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleSort}>
                    Last Updated <BiSortAlt2 style={{ marginLeft: '5px' }} />
                </div>
            ),
            selector: (row) => row.createdDate,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0px' }}>
                    <button aria-label="Edit" style={{
                        width: "60px",
                        height: "30px",
                        padding: "2px 2px",
                        cursor: "pointer",
                        justifyContent: "center",
                        margin: "12px 12px 12px 12px",
                        backgroundColor: "#0A66C2",
                        color: "white"

                    }}
                        onClick={() => {
                            handleEdit(row);
                            setSalespersonloggedin(false);
                        }} >Edit </button>
                    <button aria-label="Delete" style={{
                        width: "60px",
                        height: "30px",
                        padding: "2px 2px",
                        cursor: "pointer",
                        justifyContent: "center",
                        margin: "12px 12px 12px 12px"
                    }}
                        onClick={() => handleDelete(row)} >Delete</button>
                </div>
            ),
        },
    ];

    const tableHeaderStyle = {
        headCells: {
            style: {
                height: "90px",
                fontWeight: 800,
                fontSize: "16px",
                backgroundColor: "white",
                lineHeight: "19.2px",
                fontFamily: "Lato",
                textAlign: "left",
                borderBottom: "none",
            },
        },
        cells: {
            style: {
                minWidth: '280px', // Ensure all cells have a minimum width
            },
        },
    };

    const navigateToDashboard = () => {
        navigate('/dashboard');
    };
    const handleConfirm = (name, EmailID) =>{
        if(name === "confirm"){
            deleteUserByEmail(EmailID)
            .then((result) => {
                if (result.success) {
                    // Handle successful deletion
                    setFetchusers(true);
                    setPopupVisible(false);
                    setOpen(false)
                } else {
                    // Handle failure
                    console.error(result.message);
                }
            })
            .catch((error) => {
                console.error('Error during deletion:', error);
            });
        }
        if(name === "cancel"){
            setOpen(false)
        }
        
    }


    return (
        <div>
            {loader && (
                <div className="loader-overlay">
                    <FadeLoader />
                </div>
            )}
            <Navbar userData={userData} />

            {/* Check if registerOwner is true */}
            {registerOwner ? (
                <OwnerRegistration registerOwner={registerOwner} handleUserdetails={handleUserdetails} handleRegsiterOwner={handleRegsiterOwner}
                    empId={stateLogedInUser}
                />
            ) : (
                // If registerOwner is false and isEdit is false, display the table
                !isEdit ? (
                    <div>
                        <div className='container-fluid p-0'>
                            <div className="lotmanagement">
                                <div className="row alignLeft">
                                    <div className="col-8">
                                        <div className='viewHeader'>Registered Parking Owners</div>
                                    </div>
                                    <div className="col-4">
                                        <input type='text' className="form-control" placeholder='Search by address.' onChange={(e) => setSearch(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row alignLeft">
                                    <div className="col-12 mb-2">
                                        Total Registered Owners: {userDetails.length}
                                    </div>
                                </div>

                                {/* DataTable displaying parking lots */}
                                <React.Fragment>
                                    <DataTable
                                        customStyles={{
                                            headCells: tableHeaderStyle.headCells,
                                            cells: {
                                                style: {
                                                    fontFamily: 'Lato',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                    lineHeight: '18.6px',
                                                    textAlign: 'left',
                                                    height: "60px",
                                                    borderBottom: "none",
                                                },
                                            },
                                        }}
                                        columns={columns}
                                        data={filter.length ? filter : []} // Pass empty array to prevent row rendering
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                        persistTableHead
                                        noDataComponent={
                                            <div style={{ padding: '20px 0', textAlign: 'center' }}>
                                                No records found
                                            </div>
                                        }
                                    />
                                </React.Fragment>
                                <div className=" add-property-container alignLeft">
                                <button className="add-property-btn" onClick={() => handleRegsiterOwner(true)} > Register Employee</button>
                            </div>
                            </div>
                            
                        </div>

                        {/* Delete confirmation dialog */}
                        <Dialog
                            classes={{ paper: 'custom-paper-class-delete' }}
                            aria-labelledby="customized-dialog-title"
                            dividers
                            open={open}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <DialogTitle dividers sx={{ textAlign: 'start', color: "#000;", fontSize: "24px", fontWeight: "700", padding: "5px 24px " }} id="customized-dialog-title">
                                Are you Sure You Want to Delete This Property?
                            </DialogTitle>
                            <DialogContent style={{ border: "none", padding: "5px 22px", color: "#000;" }}>
                                <Typography gutterBottom>
                                    <span>Once you delete this property, you will not be able to retrieve the data. This action is irreversible. Please confirm if you wish to proceed.</span>
                                </Typography>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'right' }}>
                                <Button
                                    className='custom-button-cancel'
                                 onClick={() => handleConfirm('cancel')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className='custom-button-delete'
                                  onClick={() => handleConfirm('confirm', selectedRowEmailID)}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                ) : (
                    // Display MapDashboard when isEdit is true
                    <MapDashboard defaultaddress={defaultaddress} userData={userData} entityId={userData?.entityRegistrationID} empId={stateLogedInUser} />
                )
            )}
        </div>
    );


};

export default OwnerRegistationDashboard;
