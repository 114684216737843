import React, { useState, useEffect } from 'react';
import RobotaxiLogin from '../../Images/LoginImage.png';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ValidationMessages from '../utils/validationMessages';
import API_URLS from '../apiCalls/apiUrls';
import './PasswordRequirement.css';
import {getPasswordRequirements} from '../utils/passwordRquirement';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const CreatePassword = (props) => {
    const hostPath = process.env.REACT_APP_API_BASE_URL;
    const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
    const navigate = useNavigate();
    const [loginUserData, setLoginUserData] = useState({
        NewPassword: '',
        ConfirmPassword: ''
    });
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const passwordRequirements = getPasswordRequirements(loginUserData.NewPassword);
    const validateForm = () => {
        const errors = {};
        if (!loginUserData.NewPassword) {
            errors.NewPassword = ValidationMessages.newPasswordRequired;
        }
        if (!loginUserData.ConfirmPassword) {
            errors.ConfirmPassword = ValidationMessages.confirmPasswordRequired;
        }
        if (loginUserData.NewPassword !== loginUserData.ConfirmPassword) {
            errors.ConfirmPassword = ValidationMessages.newandconfirmPasswordShouldMatch;
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const changePassword = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const UserData = {
                EmailId: props.ownerlogin.EmailId,
                OldPassword: props.ownerlogin.tempPassword,
                NewPassword: loginUserData.ConfirmPassword,
            };
            const requestOptions = {
                method: "PUT",
                headers: { "x-functions-key": `${hostHeader}` },
                body: UserData,
            };
            axios
                .put(`${hostPath}${API_URLS.BASE_URL_UpdatePassword}`, requestOptions.body, {
                    headers: requestOptions.headers,
                })
                .then((response) => {
                    if (response.data.status === true) {
                        toast.success(response.data.responseMessage);
                        navigate("/dashboard");

                    } else if (response.data.status === false) {
                        toast.error(response.data.responseMessage);
                    }
                })
                .catch((error) => {
                    toast.warning(error);
                });
        };
    }
    return (
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-7 p-0">
                    <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
                </div>
                <div className="col-md-5">
                    <form className="login-form loginfirst">
                        <div className="login">
                            <h3 className='loginheader textleft'>Create New Password</h3>
                            <p className="mb-4 loginDescription textleft">It's time to set a fresh password and secure your account! Please create a password that’s both strong and memorable</p>
                        </div>
                        <div className="Username mb-4 textleft createPassword">
                            <label htmlFor="username" className='userName loginlabel'>New Password </label><span className='mandatorymark loginlabel'>*</span>
                            <div className="password-container" style={{ position: 'relative' }}>
                                <input type={passwordVisible ? "text" : "password"}
                                    className={`form-control ${loginUserData.NewPassword ? 'input-active' : ''}`}
                                    id="password"
                                    placeholder='Enter New Password*'
                                    value={loginUserData.NewPassword}
                                    onChange={(e) => setLoginUserData({ ...loginUserData, NewPassword: e.target.value })} />
                                <IconButton
                                    data-testid="toggle-password-visibility"
                                    onClick={togglePasswordVisibility}
                                    className="toggle-password-visibility"
                                >
                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </div>

                            {errors.NewPassword && <div className="error-message mandatorymark">{errors.NewPassword}</div>}
                        </div>
                        <p className="StrongPassword-H">
                            To ensure your account security, please create a strong password meeting these requirements:
                        </p>
                        <ul className="password-list">
                            <li className={passwordRequirements.minLength ? "password-requirement-success" : "password-requirement"}>
                                {passwordRequirements.minLength ? (
                                    <CheckCircleOutlineIcon className="requirement-icon" />
                                ) : (
                                    <HighlightOffIcon className="requirement-icon" />
                                )}
                                At least 8 characters
                            </li>
                            <li className={passwordRequirements.uppercase ? "password-requirement-success" : "password-requirement"}>
                                {passwordRequirements.uppercase ? (
                                    <CheckCircleOutlineIcon className="requirement-icon" />
                                ) : (
                                    <HighlightOffIcon className="requirement-icon" />
                                )}
                                Includes at least one uppercase letter (A-Z)
                            </li>
                            <li className={passwordRequirements.lowercase ? "password-requirement-success" : "password-requirement"}>
                                {passwordRequirements.lowercase ? (
                                    <CheckCircleOutlineIcon className="requirement-icon" />
                                ) : (
                                    <HighlightOffIcon className="requirement-icon" />
                                )}
                                Includes at least one lowercase letter (a-z)
                            </li>
                            <li className={passwordRequirements.digit ? "password-requirement-success" : "password-requirement"}>
                                {passwordRequirements.digit ? (
                                    <CheckCircleOutlineIcon className="requirement-icon" />
                                ) : (
                                    <HighlightOffIcon className="requirement-icon" />
                                )}
                                Includes at least one digit (0-9)
                            </li>
                            <li className={passwordRequirements.specialChar ? "password-requirement-success" : "password-requirement"}>
                                {passwordRequirements.specialChar ? (
                                    <CheckCircleOutlineIcon className="requirement-icon" />
                                ) : (
                                    <HighlightOffIcon className="requirement-icon" />
                                )}
                                Includes at least one special character (!, @, #, $, %, ^, &, *)
                            </li>
                        </ul>

                        <div className="Password mb-3 textleft">
                            <label htmlFor="password" className='userName loginlabel'>Confirm New Password*</label><span className='mandatorymark loginlabel'>*</span>
                            <div className="password-container" style={{ position: 'relative' }}>
                                <input type={passwordVisible ? "text" : "password"}
                                    className={`form-control ${loginUserData.ConfirmPassword ? 'input-active' : ''}`}
                                    id="password"
                                    placeholder='Enter Confirm Password*'
                                    value={loginUserData.ConfirmPassword}
                                    onChange={(e) => setLoginUserData({ ...loginUserData, ConfirmPassword: e.target.value })} />
                                <IconButton
                                    data-testid="toggle-password-visibility"
                                    onClick={togglePasswordVisibility}
                                    className="toggle-password-visibility"
                                >
                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </div>
                            {errors.ConfirmPassword && <div className="error-message mandatorymark">{errors.ConfirmPassword}</div>}
                        </div>
                        <button type="submit" className='btnn-primary mb-3 login-button btn-createPassword' onClick={changePassword}>Change Password</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default CreatePassword;
