// Scheduler.jsx
import React, { useState, useCallback, useEffect } from 'react';
import CalendarComponent from './CalendarComponent.jsx';
import AvailabilityModal from './AvailabilityModal.jsx';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { addDays, addWeeks, addMonths, format } from 'date-fns';

const Scheduler = (props) => {
  const {
    overrides,
    handleradiobutton,
    selectedDate,
    handleNavigate,
    logEvents,
    selectedValue,
    timeValues,
    errors,
    handleSave,        
    isSaveDisabled, 
    handleChange,
    handleStepsback,
    handleSteps,
    bankdetails,
    EmailId,
    handlebankaccountdetails,
    timeSlotesAvailability,
    toggleCondition,
    events,
    setEvents,
    ...rest
  } = props;
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [validatedEvents, setValidatedEvents] = useState([]);

  const handleEventsValidated = useCallback((validatedEvents) => {
    setValidatedEvents(validatedEvents);
  }, []);

// Scheduler.jsx

useEffect(() => {
  console.log('Scheduler - events before conversion to calendarEvents:', events);

  const convertedEvents = events.map((event) => ({
      ...event,
      start: new Date(event.Start || event.start),
      end: new Date(event.End || event.end),
      title: event.Title || event.title,
      id: event.Id || event.id,
  }));

  console.log('Scheduler - calendarEvents after conversion:', convertedEvents);
  setCalendarEvents(convertedEvents);
}, [events]);



  const handleAddEvent = (start) => {
    setSelectedEvent({
      Id: new Date().getTime(),
      Title: '',
      Start: start,
      End: new Date(start.getTime() + 60 * 60 * 1000), // Add 1 hour
      AllDay: false,
      Recurrence: null,
      RecurrenceId: new Date().getTime(),
      SelectedDays: [],
      EndType: 'never',
      EndDateOn: null,
    });
    setIsEditing(false);
    setShowModal(true);
  };

  const handleNextButtonClick = () => {
    // Validate time slot availability
    timeSlotesAvailability();
    // Move to the next step
    toggleCondition('4 of 5 - Enter Bank Details');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  const handleBackButtonClick = () => {
    props.handleConfirm(false);
    toggleCondition('2 of 5 - Mark Parking Spaces & Other Areas');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  const handleSelectEvent = (event) => {
    logEvents();
    setSelectedEvent(event);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleSaveEvent = (newEvent, recurrenceChanged) => {
    console.log('Scheduler - Event received from AvailabilityModal:', newEvent);

    const savedEvent = { ...newEvent };

    if (savedEvent.EndType === 'never' && !savedEvent.EndDateOn) {
      const oneYearLater = new Date(savedEvent.Start);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      savedEvent.EndDateOn = oneYearLater.toISOString();
    }

    if (savedEvent.Recurrence) {
      if (isEditing) {
        // Remove old instances
        setEvents((prevEvents) =>
          prevEvents.filter(
            (event) => event.RecurrenceId !== selectedEvent.RecurrenceId
          )
        );
      }
      const recurringEvents = generateRecurringEvents(savedEvent);
      setEvents((prevEvents) => [...prevEvents, ...recurringEvents]);
    } else {
      if (isEditing) {
        setEvents((prevEvents) =>
          prevEvents.map((event) =>
            event.Id === selectedEvent.Id ? savedEvent : event
          )
        );
      } else {
        savedEvent.Id = new Date().getTime();
        setEvents((prevEvents) => [...prevEvents, savedEvent]);
      }
    }

    handleCloseModal();
  };

  const handleDeleteEvent = (event, deleteSeries) => {
    console.log('Deleting event:', event, 'Delete entire series:', deleteSeries);
    if (deleteSeries && event.Recurrence) {
      setEvents((prevEvents) =>
        prevEvents.filter((e) => e.RecurrenceId !== event.RecurrenceId)
      );
    } else {
      setEvents((prevEvents) => prevEvents.filter((e) => e.Id !== event.Id));
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setIsEditing(false);
  };

  // Recurrence Generation Functions
  const generateCustomRecurringEvents = (event, startDate, duration, occurrences, endDateOn) => {
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const selectedDays = event.SelectedDays && event.SelectedDays.length > 0
      ? event.SelectedDays
      : [format(startDate, 'EEE')]; // Use selected days or fallback to start day

    const recurrenceInterval = parseInt(event.CustomRecurrenceCount, 10) || 1;

    console.log('Initial Date:', currentDate);
    console.log('Recurrence Interval:', recurrenceInterval);
    console.log('Selected Days (for weekly):', selectedDays);
    console.log('End Date On:', endDateOn);

    if (isNaN(currentDate.getTime())) {
      console.error('Invalid startDate:', startDate);
      return events;
    }

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      // Process each selected day in the week
      for (const day of selectedDays) {
        const currentDayIndex = currentDate.getDay();
        const targetDayIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(day);

        // Calculate the difference between current day and target day
        const dayDifference = targetDayIndex - currentDayIndex;
        const eventDate = new Date(currentDate);
        eventDate.setDate(currentDate.getDate() + dayDifference);

        if (
          eventDate >= new Date(startDate) &&
          (!endDateOn || eventDate <= new Date(endDateOn))
        ) {
          console.log('Processing custom weekly event for date:', eventDate);
          events.push({
            ...event,
            start: new Date(eventDate),
            end: new Date(eventDate.getTime() + duration),
          });

          console.log('Adding custom weekly event for date:', eventDate);
          i++;
        }
      }

      // Move to the next recurrence interval (next week)
      currentDate = addWeeks(currentDate, recurrenceInterval);
      console.log(`Next Date based on Weekly Interval of ${recurrenceInterval} weeks:`, currentDate);

      if (isNaN(currentDate.getTime())) {
        console.error('Invalid currentDate during recurrence generation:', currentDate);
        break;
      }
    }

    console.log('Generated custom recurring events:', events);
    return events;
  };

  const generateWeeklyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn,
    interval = 1
  ) => {
    console.log('Generating weekly recurring events:', event);
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const selectedDays = event.SelectedDays.length
      ? event.SelectedDays
      : [format(currentDate, 'EEE')];

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log(
        'Checking for valid day:',
        format(currentDate, 'EEE'),
        'with selected days:',
        selectedDays
      );
      if (selectedDays.includes(format(currentDate, 'EEE'))) {
        console.log('Adding event for date:', currentDate);
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }

      currentDate = calculateNextWeeklyDate(currentDate, interval);
    }

    return events;
  };

  const generateMonthlyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log('Generating monthly recurring events:', event);
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      console.log('Adding event for date:', currentDate);
      events.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      currentDate = calculateNextMonthlyDate(
        currentDate,
        event.CustomRecurrenceCount || 1
      );
      i++;
    }

    return events;
  };

  const generateDailyRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    const recurrenceInterval = event.CustomRecurrenceCount || 1;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      events.push({
        ...event,
        start: new Date(currentDate),
        end: new Date(currentDate.getTime() + duration),
      });

      i++;
      currentDate = addDays(currentDate, recurrenceInterval);
    }

    return events;
  };

  const generateWeekdayRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log('Generating weekday events');
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        // Monday to Friday
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }
      currentDate = addDays(currentDate, 1);
    }

    return events;
  };

  const generateWeekendRecurringEvents = (
    event,
    startDate,
    duration,
    occurrences,
    endDateOn
  ) => {
    console.log('Generating weekend events');
    const events = [];
    let currentDate = new Date(startDate);
    let i = 0;

    while (
      (!occurrences || i < occurrences) &&
      (!endDateOn || currentDate <= new Date(endDateOn))
    ) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // Saturday or Sunday
        events.push({
          ...event,
          start: new Date(currentDate),
          end: new Date(currentDate.getTime() + duration),
        });
        i++;
      }
      currentDate = addDays(currentDate, 1);
    }

    return events;
  };

  // Helper methods
  const calculateNextWeeklyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + interval * 7);
    return nextDate;
  };

  const calculateNextMonthlyDate = (currentDate, interval) => {
    let nextDate = new Date(currentDate);
    nextDate.setMonth(nextDate.getMonth() + interval);
    return nextDate;
  };

  // Function to generate recurring events
  const generateRecurringEvents = (event) => {
    console.log('Event Recurrence property value:', event.Recurrence);

    let events = [];

    const startDate = new Date(event.Start);
    const endDate = new Date(event.End);
    const duration = endDate - startDate;

    let occurrences = event.EndAfter || null;
    let endDateOn = event.EndDateOn ? new Date(event.EndDateOn) : null;

    // If the recurrence is set to never, impose a 1-year limit
    if (!occurrences && !endDateOn && event.EndType === 'never') {
      const oneYearLater = new Date(startDate);
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      endDateOn = oneYearLater;
    }

    console.log('Generating events for Recurrence:', event.Recurrence, {
      startDate,
      duration,
      occurrences,
      endDateOn,
    });

    switch (event.Recurrence) {
      case 'daily':
        events = generateDailyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case 'weekly':
        events = generateWeeklyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case 'monthly':
        events = generateMonthlyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case 'custom':
        events = generateCustomRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case 'bi-weekly':
        console.log(
          'This is the event.Recurrence property for bi-weekly',
          event.Recurrence
        );
        events = generateWeeklyRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn,
          2
        ); // Bi-weekly treated as 2 weeks interval
        break;
      case 'weekdays':
        console.log(
          'This is the event.Recurrence property for weekdays',
          event.Recurrence
        );
        events = generateWeekdayRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      case 'weekends':
        console.log(
          'This is the event.Recurrence property for weekends',
          event.Recurrence
        );
        events = generateWeekendRecurringEvents(
          event,
          startDate,
          duration,
          occurrences,
          endDateOn
        );
        break;
      default:
        console.warn('Unknown recurrence type:', event.Recurrence);
        break;
    }

    return events.map((e) => ({
      ...e,
      RecurrenceId: event.RecurrenceId || new Date().getTime(),
      Start: e.start.toISOString(),
      End: e.end.toISOString(),
    }));
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const backButtonClick = () => {
    props.handleConfirm(false);
    toggleCondition('2 of 5 - Mark Pick up and Drop off Area');
    toggleCondition('3 of 5 - Schedule Availability');
  };

  return (
    <div className="Scheduler">
      <CalendarComponent
        events={calendarEvents}
        onSelectEvent={handleSelectEvent}
        onAddEvent={handleAddEvent}
        onOpenModal={() => setShowModal(true)}
        onEventsValidated={handleEventsValidated}
        selectedDate={selectedDate}
        onNavigate={handleNavigate}
      />
      <div className="mb-3">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          style={{ marginTop: '2vh' }}
        >
          Set Availability
        </Button>
      </div>
      <AvailabilityModal
        show={showModal}
        onClose={handleCloseModal}
        onSave={handleSaveEvent}
        onDelete={handleDeleteEvent}
        isEditing={isEditing}
        eventDetails={
          selectedEvent || {
            Title: '',
            Start: '',
            End: '',
            Recurrence: '',
            AllDay: false,
          }
        }
      />
      <div style={{ textAlign: 'end', marginTop: '-5vh', marginRight: '7vw' }}>
        <div
          className="back-wrapper"
          style={{
            width: '100px',
            backgroundColor: '#fff',
            backgroundColor: '#6ACD54',
            boxShadow: '0 0 3px 2px #cec7c759',
          }}
          onClick={handleBackButtonClick}
        >
          <b className="next">Go Back</b>
        </div>
        <div
          className="next-wrapper"
          onClick={handleNextButtonClick}
          style={{
            
            backgroundColor: '#fff',
            boxShadow: '0 0 3px 2px #cec7c759',
          }}
        >
          <b className="next">Next</b>
        </div>
        <div
        className="autosave1-wrapper"
        onClick={(e) => handleSave(e)}
        style={{
          pointerEvents: isSaveDisabled ? 'none' : 'auto',
          opacity: isSaveDisabled ? 0.5 : 1,
          marginRight:'30px',
            backgroundColor: '#fff',
            boxShadow: '0 0 3px 2px #cec7c759',
        
        }}
      >
        <b className="next">Save</b>
        
      </div>

      </div>
    </div>
  );
};

export default Scheduler;
