import React, { useState, useEffect, useRef } from "react";
import RobotaxiLogin from "../../Images/LoginImage.png";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

const PhoneVerification = (props) => {
  const hostPath = process.env.REACT_APP_API_BASE_URL;
  const hostHeader = process.env.REACT_APP_CUSTOM_HEADER;
  const [enterdotp, setenteredOtp] = useState(new Array(5).fill(""));
  const [isFlagSet, setIsFlagSet] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const [showResend, setShowResend] = useState(false);
  const [timeLeft, setTimeLeft] = useState(270);
  const [otpCount, setOtpCount] = useState(1);
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  useEffect(() => {
    const resendTimer = setTimeout(() => {
      setShowResend(true);
    }, 60000); // Enable the resend button after 60 seconds

    const countdown = setInterval(() => {
      //If the time left is 0, clear the interval and show the session expired dialog
      setTimeLeft((timeLeft) => {
        if (timeLeft === 0) {
          clearInterval(countdown);
          setIsVerifyDisabled(true);
          setShowResend(false);
          setValidationMessage("");
          setSessionExpired(true); 
          return 0;
        } else {
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => {
      clearTimeout(resendTimer);
      clearInterval(countdown);
    };
  }, []);

  const handleOtpChange = (value) => {
    setValidationMessage("");
    const newOtp = value.split('').map(Number);
    setenteredOtp(newOtp);
    }

    const handlePhonestate = () => {
      setValidationMessage(false);
      setShowResend(false);
      setTimeLeft(270);
      setOtpCount(1);
      setenteredOtp(new Array(5).fill(""))
      }

  //Verifies the OTP entered by the user by sending a POST request to the API
  const handleButtonClick = async (e) => {
    e.preventDefault();
    if (!enterdotp || enterdotp.join("") === "") {
      setValidationMessage("Please enter a valid OTP.");
      return;
    }
    let mobilenum =  props.formData?.MobileNumber;
    if(props.oldNumber !== props.formData?.MobileNumber && !props.otpSentRef && props.settingpage){
      mobilenum = props.oldNumber;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "x-functions-key": hostHeader,
      },
      body: JSON.stringify({
        CellNumber: mobilenum,
        OTP: enterdotp.join(""),
      }),
    };
    const response = await axios.post(
      `${hostPath}/api/VerifySMSOtp`,
      requestOptions.body,
      { headers: requestOptions.headers }
    );
    //If OTP is verified successfully, redirect the user to the account info page
    if (response.data && response.data.status === true) {
      setIsFlagSet(true);
      if(!props.settingpage){
      setTimeout(() => {
        props.phoneVerificationSuccess?.(true);
        props.setAccountInfo?.(true);
      }, 2000);
     }
     else{
      setTimeout(() => {
        if(props.emailSentRef && !props.otpSentRef && props.radiobuttonval ==='phone' ){
          if(props.verificationRequired === 'email') {
            window.alert("New Email Verification Initiated Check your new email for the one time verification code.");
            }
            else if(props.verificationRequired === 'emailmobile'){
              window.alert("New Email and phone Verification Initiated Check your new email and phone for the one time verification code.");
            }
            else if(props.verificationRequired === 'mobile'){
              window.alert("New Phone Verification Initiated Check your phone for the one time verification code.");
            }
          setIsFlagSet(false);
          handlePhonestate();
        }
        else{
        props.phoneVerificationSuccess?.(true);
        }
        if(props.otpSentRef){
          props.updateProfile(props.profiledata);
          navigate('/login');
          window.alert("Please Login to the application");
        }
      }, 2000);
      setTimeout(() => {
      props.handleverificationemail();
    }, 2000);
     }
      setValidationMessage("");
    } else {
      setValidationMessage("Please enter a valid OTP.");
    }
  };

  const accountinfopage = () => {
    props.onAccountpage?.(true);
  };
  const reloadPage = () => {
    window.location.reload();
  };

  const goBack = (e) => {
    if(!props.settingpage){
      props.onEnityInfo();
    }
    else{
      e.preventDefault(); 
      reloadPage();
    }
  };

  //Max 3 attempts allowed for OTP verification - 1st attempt + 2 retries
  const resendlink = () => {
    if (otpCount > 2) {
      if (otpCount > 2) {
        setIsVerifyDisabled(true);
        setTimeLeft(0);
        setShowResend(false);
        setValidationMessage("");
        setSessionExpired(true);
      }
    } else {
      if(props.oldNumber !== props.formData?.MobileNumber && !props.otpSentRef && props.settingpage){
        props.sendOTPByPhone?.({ MobileNumber: props.oldNumber });
      }
      else{
      props.sendOTPByPhone?.({ MobileNumber: props.formData?.MobileNumber });
      }
      setTimeLeft(270);
      setValidationMessage("The message has been sent successfully!!");
      setTimeout(() => {
        setValidationMessage("");
      }, 5000); //display the message only for 5 seconds and then disappear
      setShowResend(false);
      setTimeout(() => {
        setShowResend(true);
      }, 60000); //enable the resend button after 60 seconds
      setOtpCount(otpCount + 1); 
      setenteredOtp(new Array(5).fill(""));
    }
  };
  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-7 p-0">
          <img src={RobotaxiLogin} alt="Login" className="GeoparkingImage" />
        </div>
        <div className="col-md-5 d-flex align-items-center justify-content-center">
          {isFlagSet ? (
            <div className="login-form loginfirst">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="160"
                  viewBox="0 0 160 160"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M80.0926 145.799C43.9006 120.43 24.0286 88.2168 24.0286 54.9155V39.6693L80.0926 14.7271L136.157 39.6693V54.9155C136.157 88.2168 116.285 120.43 80.0926 145.799Z"
                    fill="#E6E9ED"
                  />
                  <path
                    d="M80.0925 99.1928C95.368 99.1928 107.751 86.8096 107.751 71.5342C107.751 56.2587 95.368 43.8755 80.0925 43.8755C64.817 43.8755 52.4338 56.2587 52.4338 71.5342C52.4338 86.8096 64.817 99.1928 80.0925 99.1928Z"
                    fill="#82D65F"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M75.0615 83.6622C74.4997 83.6622 73.9628 83.4382 73.5682 83.0435L65.0881 74.5635C64.2633 73.7386 64.2633 72.3982 65.0881 71.5733C65.913 70.7484 67.2535 70.7484 68.0784 71.5733L75.0615 78.5564L91.5593 62.0586C92.3842 61.2337 93.7246 61.2337 94.5495 62.0586C95.3744 62.8835 95.3744 64.2239 94.5495 65.0488L76.5584 83.0399C76.1602 83.4382 75.6233 83.6622 75.0615 83.6622Z"
                    fill="#E6E9ED"
                  />
                </svg>
              </div>
              <div className="login">
                <h3 className="loginheader " style={{ marginTop: "150px" }}>
                 One time verification code verified !
                </h3>
                <p className="mb-4 loginDescription">
                  Your one time verification code has been successfully verified. Get ready to
                  complete your registration by setting up your account. We're
                  redirecting you to the final step now. Let's get started!
                </p>
              </div>
              <div>
                {" "}
                <p className="mb-4 loginDescription">
                  In case you are not redirected automatically{" "}
                  <a onClick={accountinfopage} className="joingeo">
                    click here
                  </a>
                </p>
              </div>
            </div>
          ) : (
            <form
              className="login-form loginfirst"
              onSubmit={handleButtonClick}
            >
              <div className="login">
                <h3 className="loginheader textleft">Phone Verification</h3>
                <p className="mb-4 loginDescription textleft">
                { (props.oldNumber !== props.formData?.MobileNumber && !props.otpSentRef && props.settingpage)?
                `One time verification code has been sent at: ${props.oldNumber}`
                  : 
                  `One time verification code has been sent at: ${props.formData?.MobileNumber}` 
                }
                  <Link
                    onClick={goBack}
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {" "}
                    {/* Change Phone Number? */}
                  </Link>
                </p>
                <p className="mb-4 loginDescription textleft">
                  One time verification code expires in: {Math.floor(timeLeft / 60)}:
                  {timeLeft % 60 < 10 ? "0" : ""}
                  {timeLeft % 60}
                </p>
              </div>
              <div className="otp-input mb-4">
              <input
                disabled={isVerifyDisabled}
                type="text"
                className={`form-control ${
                  setenteredOtp ? "input-active" : ""
                }`}
                value={enterdotp.join('')}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[0-9]*$/.test(inputValue)) {
                    handleOtpChange(inputValue);
                  } else {
                    console.error("Invalid input: Only numbers are allowed.");
                  }
                }}
                maxLength={5}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                      document.querySelector('.nextBtn').click();
                  }
              }}
              />
              </div>
              {validationMessage && (
                <div className="text-danger">{validationMessage}</div>
              )}
              <div className="mb-2">
                {showResend && (
                  <p>
                    {" "}
                    Did not receive OTP? {" "}
                    <Link
                      onClick={resendlink}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      Resend one time verification code
                    </Link>
                  </p>
                )}
                <p className="mb-4 loginDescription textleft">
                  If you haven't received the message, please try waiting a few minutes.
                </p>
              </div>
              <div className="d-flex justify-content-between mb-5">
                <Link onClick={goBack} className="joingeo">
                  Go Back
                </Link>
                <button
                  type="submit"
                  disabled={isVerifyDisabled}
                  className="nextBtn"
                >
                  Verify
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <BootstrapDialog
        onClose={goBack}
        classes={{ paper: "custom-paper-class" }}
        aria-labelledby="customized-dialog-title"
        dividers
        sx={{
          textAlign: "start",
          overflowY: "auto",
          padding: "5rem 5rem",
          borderRadius: "5px",
        }}
        open={sessionExpired}
      >
        <DialogTitle
          dividers
          sx={{
            textAlign: "start",
            color: "rgba(51, 51, 51, 1)",
            fontSize: "24px",
            fontWeight: "700",
          }}
          id="session-expired-dialog-title"
        >
          <b>Session Expired</b>
        </DialogTitle>

        <DialogContent>
          <Typography gutterBottom>
            Your one time verification code has expired or you have either exceeded the allowed number
            of one time verification code phone attempts, Please go back and start the phone
            verification process again.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            className="mb-3"
            autoFocus
            classes={{ root: "Secondary" }}
            sx={{
              width: "200px",
              height: "50px",
              backgroundColor: "#6BCD55",
              color: "#000",
              fontFamily: "Montserrat",
              fontSize: "17px",
              fontStyle: "normal",
              fontWeight: 700,
              "&:hover": { backgroundColor: "#5aa949" },
            }}
            onClick={goBack}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default PhoneVerification;
